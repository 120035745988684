.payment-container {
    height: 100vh;
    display: flex;
    gap: 4rem;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        display: none;
    }

    .image {
        img {
            width: 100%;
            height: 100%;
        }
    }

    .payment-info {
        padding-top: 5rem;
        width: 550px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;

        .payment {
            display: flex;
            flex-direction: column;
            gap: 2rem;

            .heading {
                font-size: 40px;
                font-weight: 500;
                letter-spacing: 3px;
            }

            .divider {
                width: 100%;
                height: 2px;
                border-bottom: 1px solid #afabab;
            }

            .order {
                display: flex;
                gap: 2rem;
                font-size: 30px;
                letter-spacing: 3px;
                font-family: "darkFont";
                text-transform: uppercase;

                span {
                    color: #3f3e3e;
                }
            }

            .name-field {
                display: flex;
                justify-content: space-between;

                input {
                    font-size: 25px;
                    padding: 1px;
                    letter-spacing: 2px;
                    border-bottom: 1px solid #afabab;
                }
            }
        }

        .expiry {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .flex {
                display: flex;
                flex-direction: column;
                gap: 2rem;

                .label {
                    font-size: 30px;
                    letter-spacing: 3px;
                    font-family: "darkFont";
                    text-transform: uppercase;
                }
                .input{
                    display: flex;
                }

                input {
                    font-size: 25px;
                    padding: 3px 0;
                    border-bottom: 1px solid #afabab;
                    letter-spacing: 2px;
                }
                .first{
                    width: 70px;

                }
                .second{
                    width: 180px;
                }
                .ccv{
                    width: 250px;
                }
            }


        }

    }

}