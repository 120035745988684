
.teacherDetailMainContainer {
    padding-top: 10rem;
    // padding: 10rem 1.5rem 2rem 4rem;
    max-width: 1400px;
    margin: 0 auto;

    .name {
        font-size: 25px;
        line-height: 30px;
        font-weight: 700;
        color: #fcd117;
        font-style: normal;
        padding: 1rem 0;
    }

    .detailMain {
        display: flex;
        gap: 3rem;
        .teacherdetailsMain{
            min-width: 8rem;
        }
        .genderTeacher{
            text-transform: capitalize;
        }

    // }

    .teachersContainer {
        width: 65%;
        display: flex;
        flex-direction: column;
        margin-right: 5px;
        gap: 1.5rem;

        @media screen and (max-width:1340px) {
            width: 70%;
        }

        @media screen and (max-width:1025px) {
            width: 100%;
         }

        .teacherProfile {
            width: 90%;
            padding: 0.7rem;

            // @media screen and (max-width:1340px) {
            //     width: 35%;
            // }



            .teacherImgName {
                display: flex;
                justify-content: center;
                align-items: end;

                .TeacherImg {
                    border: 0.744186px solid #FFFAE3;
                    border-radius: 7px;
                    height: 200px;
                    width: 200px;
                    object-fit: cover;

                }

                .TeacherName {
                    width: 65%;
                    padding: 0 1rem;
                    color: black;
                    line-height: 30px;
                    letter-spacing: 0em;
                    font-family: Poppins;
                    font-size: 20px;

                    .teacherName {
                        font-weight: 400;
                    }

                    .teacherPrice {
                        font-weight: 500;
                    }

                }
            }

            .detail {
                padding: 0.7rem;
                font-size: 13px;
                line-height: 13px;
            }


        }

        .workText {
            font-family: "Poppins";
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0em;
            color: black;

        }

        .table {
            width: 100%;
            table{
                width: 100% !important;
            }
            .ant-table-wrapper .ant-table{
                @media screen and (max-width:500px) {
                    font-size: 11px;
                }
            }
            .ant-table-wrapper .ant-table-thead >tr>th{
                background: var(--themeColor);
                font-weight: bold;
                width: fit-content;
            }

            .ant-table-content {
                table {
                    border-spacing: 0 0.5rem;
                }
            }

            :where(.css-dev-only-do-not-override-k7429z).ant-table-wrapper .ant-table-thead>tr>th,
            :where(.css-dev-only-do-not-override-k7429z).ant-table-wrapper .ant-table-thead>tr>td {
                background: #FCD117;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
                border-bottom: 0;
            }

            :where(.css-dev-only-do-not-override-k7429z).ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
            :where(.css-dev-only-do-not-override-k7429z).ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
                // background-color: #FCD117;
                background: #FCD117;
            }

            .ant-table-wrapper::after {
                display: none;
                clear: both;
                content: "";
            }

            .ant-table-wrapper .ant-table-tbody>tr>td>.ant-table-wrapper:only-child .ant-table,
            :where(.css-dev-only-do-not-override-k7429z).ant-table-wrapper .ant-table-tbody>tr>th>.ant-table-expanded-row-fixed>.ant-table-wrapper:only-child .ant-table,
            :where(.css-dev-only-do-not-override-k7429z).ant-table-wrapper .ant-table-tbody>tr>td>.ant-table-expanded-row-fixed>.ant-table-wrapper:only-child .ant-table {
                margin-inline: 190px -16px;
            }

            .ant-table-tbody>tr {
                // background: #ffffff;
                box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.05);
                border-radius: 0px;
            }




            .tablehead {
                background: #FCD117;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
                border-radius: 5px 5px 0px 0px;
                height: 69px;
                text-align: start;

                .tableRow {
                    width: 100%;
                }

                .tableh {
                    text-align: start;
                }

                .first {
                    padding-left: 4rem;
                }

                .secend {
                    width: 60%;
                }
            }

            .tableBody {
                background: #ffffff;
                box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.05);
                border-radius: 0px;
                height: 50px;
                text-align: start;

                .tableBodyRow {
                    width: 100%;
                    margin-top: 1rem;
                }

                .tabled {
                    text-align: start;
                }

                .first {
                    padding-left: 4rem;
                }

                .secend {
                    width: 60%;
                }
            }
        }
    }
    }
    .CalendarStudent{
        width: 95%;
    }
}