.review-containers {
    padding: 4rem 0;
    overflow: hidden;

    .heading {
        text-align: center;
    }

    .review__container {
        padding-top: 4rem;
        max-width: 1400px;
        margin: 0 auto;

        .review-card {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .card-data {
                position: relative;
                width: 433px;
                height: 477px;
                margin: 0 auto;

                @media screen and (max-width:"768px") {
                    width: 370px;
                    height: 400px;
                }

                img {
                    width: 100%;
                    height: 100%;
                }

                .data {
                    position: absolute;
                    top: 30px;
                    left: 30px;
                    right: 30px;
                    bottom: 30px;
                    padding: 1.5rem;
                    display: flex;
                    flex-direction: column;
                    gap: .7rem;

                    @media screen and (max-width:"768px") {
                        top: 13px;
                        padding: 1rem;
                        gap: .5rem;

                    }

                    .d-heading {
                        text-align: left;
                        font-family: 'darkFont';
                        font-size: 25px;
                    }

                    .para {
                        line-height: 27px;
                        text-align: justify;

                        @media screen and (max-width:"768px") {
                            line-height: 22px;

                        }
                    }
                }
            }

            .profile_image {
                width: 120px;
                height: 120px;
                border-radius: 50rem;
                overflow: hidden;
                align-self: end;
                margin-left: 12rem;
                margin-top: -1.5rem;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

@media screen and (max-width:"320px") {
    .slick-track {
        left: -13px !important; 
    }
    

}