.cardFull {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;
    min-height: 900px;
    // padding: 8rem 0 1rem 0;

    .topImg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-image: url("../../Assets/Images/recite/Header-picture.png");
        height: 550px;
        margin-top: -5rem;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        object-fit: fill;

        @media screen and (max-width:768px) {
            background-position: top;
            background-size: cover;
            height: 400px;
        }

        .imgData {
            margin-top: 5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 60%;

            h2 {
                font-size: 30px;
                font-weight: 600;
                color: #FFF;
            }

            p {
                margin-top: .7rem;
                text-align: center;
                color: #FFF;
                font-size: large;
            }
        }

        @media screen and (max-width:768px) {
            .imgData {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 85%;
                text-align: center;

                h2 {
                    font-size: 20px;
                    font-weight: 600;
                }

                p {
                    color: #FFF;
                    margin-top: .5rem;
                    font-size: 15px;
                }
            }
        }

        @media screen and (max-width:400px) {
            .imgData {
                width: 90%;
                text-align: center;

                h2 {
                    font-size: 18px;
                    font-weight: 600;
                }

                p {
                    color: #FFF;
                    margin-top: .3rem;
                    font-size: 12px;
                }
            }
        }

    }

    .mainContainer {
        border-radius: 10px;
        margin: .5rem .5rem 2rem .5rem;
        width: 500px;
        min-height: 810px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: 0px 2px 30px 10px rgba(0, 0, 0, 0.15);
        position: relative;


        .card {
            display: flex;
            flex-direction: column;
            width: 98%;
            border-radius: 10px;
            background: #FFF;
            gap: 30px;

            .mediaContainer {
                height: 400px;
                padding: .5rem 0;

                iframe {
                    height: 100%;
                    width: 100%;
                }
            }

            .topData {
                .image {
                    // border: 1px solid red;
                    margin: 59px 0 30px 0;
                    object-fit: contain;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        border-radius: 50%;
                        width: 100px;
                        height: 100px;
                    }
                }

                .heading {
                    p {
                        color: #000;
                        text-align: center;
                        font-size: 13.395px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20.093px;
                    }

                    h3 {
                        color: #000;
                        margin: 10px 0 20px 0;
                        text-align: center;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 20.093px;
                    }

                }

                .details {
                    color: #323232;

                    text-align: center;
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20.093px;
                    /* 167.442% */
                }
            }

            .midDetail {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                // border: 1px solid red;


                .midImage {

                    object-fit: cover;
                    background-position: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        box-shadow: 0px 10px 15px 4px rgba(0, 0, 0, 0.25);
                    }
                }

                .imgDetails {

                    width: 55%;

                    h3 {
                        color: #000;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: bold;
                        line-height: 20.093px;
                    }

                    p {
                        color: #323232;
                        font-size: 13.395px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20.093px;

                    }
                }

                .playButton {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 55px;
                    height: 55px;
                    border-radius: 30px;
                    background: #FFF;
                    box-shadow: 0px 2px 15px 2px rgba(0, 0, 0, 0.15);
                }
            }

            .lowerDataArray {
                overflow-y: scroll;
                height: 250px;
                padding: 0.5rem 0.1rem;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .lowerData {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .endImage {
                        object-fit: cover;
                        background-position: center;

                        img {
                            width: 37px;
                            height: 37px;
                            border-radius: 50%;
                            box-shadow: 0px 5px 7.5px 2px rgba(0, 0, 0, 0.25);
                        }
                    }

                    .imgDetails {
                        p {
                            color: #323232;
                            font-size: 15px;
                            line-height: 20.093px;
                        }
                    }

                    .playButton {
                        display: flex;
                        align-items: center;

                        .playBtn {
                            cursor: pointer;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 30px;
                            height: 30px;
                            border-radius: 30px;
                            background: #FFF;
                            box-shadow: 0px 2px 15px 2px rgba(0, 0, 0, 0.15);
                            margin-right: 5px;
                        }

                        .playBtnDetail {
                            font-size: 12px;
                            font-weight: 12px;
                        }
                    }
                }
            }


        }

        .footer {
            position: sticky;
            position: -webkit-sticky;
            background: #FFF;
            box-shadow: 0px 4px 20px 8px rgba(0, 0, 0, 0.15);
            width: 100%;
            display: inline-flex;
            padding: 24px 49px 23px 48px;
            justify-content: center;
            align-items: center;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            .footerDetails {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 11px;

                p {
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20.093px;
                }

                .button {
                    button {

                        display: flex;
                        padding: 15px 30px;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        border-radius: 5px;
                        background: var(--theme-color, #FCD117);
                    }
                }
            }
        }
    }
}