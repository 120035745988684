.prayertime-container {
    padding-bottom: 7rem;

    @media screen and (max-width:"1450px") {
        padding-bottom: 5rem;
    }

    @media screen and (max-width:"1024px") {
        padding-bottom: 3rem;
    }

    .prayer-section {
        padding: 4.5rem;
        display: flex;
        justify-content: space-between;
        background-color: #FFF5C9;
        position: relative;

        @media screen and (max-width:"1024px") {
            align-items: center;
            flex-direction: column;
            gap: 3rem;
            padding: 3.5rem;
        }

        @media screen and (max-width:"768px") {
            padding: 1.5rem;

        }

        .prayer {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            @media screen and (max-width:"1024px") {
                gap: 1rem;

            }

            .para {
                font-size: 20px;
            }

            .date {
                color: #00a859;
                font-size: 20px;
                text-decoration: underline;
                font-family: 'darkFont';

            }

        }

        .timer {
            width: 400px;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            @media screen and (max-width:"1024px") {
                width: 100%;

            }

            .nimaz {
                width: 85%;
                padding: 1rem 1.5rem;
                display: flex;
                justify-content: space-between;
                font-size: 20px;
                background-color: #fee990a3;
                border-radius: 10px;
                font-family: 'darkFont';
                
                &:nth-child(even) {
                    align-self: flex-end;
                    background-color: #ccffcc9c;
                }

                @media screen and (max-width:"1024px") {
                    width: 100%;

                }
            }
        }

        .stars-img1 {
            position: absolute;
            left: -50px;
            bottom: -50px;
        }

        .stars-img2 {
            position: absolute;
            left: 8%;
            bottom: 50px;
        }

        .stars-img3 {
            position: absolute;
            left: -16%;
            bottom: 0;
        }

    }

}