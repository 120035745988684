@import "node_modules/react-modal-video/scss/modal-video.scss";
// @import 'antd/dist/antd.css';

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

body {
  font-family: "lightFont";
}

:root {
  --themeYellowColor: #fcd117;
}
.ant-modal-content {
  padding: inherit !important;
}

.ant-modal-header {
  background: #ffe98a !important;
  padding: 20px 24px;
}
.ant-modal-title {
  font-weight: 700 !important;
  font-size: 25px !important;
}
.ant-modal .ant-modal-body {
  font-size: 14px;
  text-align: center;
  padding: 13px;
}
.ant-modal .ant-modal-footer {
  justify-content: center;
  display: flex;
  margin-top: 12px;
}
.ant-btn {
    font-size: 14px;
    height: 50px;
    padding: 4px 24px;
    border-radius: 6px;
}

.ant-btn-default {
    background-color: #fcd117 !important;
    border-color: #fcd117;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}




.css-doq0dk {
  height: 400px !important;
}

.css-doq0dk .css-1fzpoyk {
  display: none;
  opacity: 0.7 !important;
  z-index: 1 !important;
}

.css-doq0dk .css-1fzpoyk:nth-child(1),
.css-doq0dk .css-1fzpoyk:nth-child(5) {
  opacity: 0.25 !important;
  z-index: 0 !important;
}

.css-doq0dk .css-1fzpoyk:nth-child(1),
.css-doq0dk .css-1fzpoyk:nth-child(2),
.css-doq0dk .css-1fzpoyk:nth-child(3),
.css-doq0dk .css-1fzpoyk:nth-child(4),
.css-doq0dk .css-1fzpoyk:nth-child(5) {
  display: block;
}

.css-doq0dk .css-1fzpoyk img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.css-doq0dk .css-1fzpoyk:nth-child(1) {
  left: 3% !important;
  width: 40%;
  transform: translateY(-50%) translateX(-36.6667%) scale(0.333333) !important;
  -webkit-transform: translateY(-50%) translateX(-36.6667%) scale(0.333333) !important;
  -moz-transform: translateY(-50%) translateX(-36.6667%) scale(0.333333) !important;
  -ms-transform: translateY(-50%) translateX(-36.6667%) scale(0.333333) !important;
  -o-transform: translateY(-50%) translateX(-36.6667%) scale(0.333333) !important;
}

.css-doq0dk .css-1fzpoyk:nth-child(2) {
  left: 25% !important;
  width: 30%;
  transform: translateY(-50%) translateX(-66.6667%) scale(0.666667) !important;
  -webkit-transform: translateY(-50%) translateX(-66.6667%) scale(0.666667) !important;
  -moz-transform: translateY(-50%) translateX(-66.6667%) scale(0.666667) !important;
  -ms-transform: translateY(-50%) translateX(-66.6667%) scale(0.666667) !important;
  -o-transform: translateY(-50%) translateX(-66.6667%) scale(0.666667) !important;
}

.css-doq0dk .css-1fzpoyk:nth-child(4) {
  width: 30%;
  left: 75% !important;
  transform: translateY(-50%) translateX(-33.3333%) scale(0.666667) !important;
  -webkit-transform: translateY(-50%) translateX(-33.3333%) scale(0.666667) !important;
  -moz-transform: translateY(-50%) translateX(-33.3333%) scale(0.666667) !important;
  -ms-transform: translateY(-50%) translateX(-33.3333%) scale(0.666667) !important;
  -o-transform: translateY(-50%) translateX(-33.3333%) scale(0.666667) !important;
}

.css-doq0dk .css-1fzpoyk:nth-child(3) {
  width: 50%;
  left: 48% !important;
  opacity: 1 !important;
  z-index: 2 !important;
  transform: translateY(-50%) translateX(-50%) scale(1) !important;
  -webkit-transform: translateY(-50%) translateX(-50%) scale(1) !important;
  -moz-transform: translateY(-50%) translateX(-50%) scale(1) !important;
  -ms-transform: translateY(-50%) translateX(-50%) scale(1) !important;
  -o-transform: translateY(-50%) translateX(-50%) scale(1) !important;
}

.css-doq0dk .css-1fzpoyk:nth-child(5) {
  left: 85% !important;
  width: 50%;
  transform: translateY(-50%) translateX(-36.6667%) scale(0.333333) !important;
  -webkit-transform: translateY(-50%) translateX(-36.6667%) scale(0.333333) !important;
  -moz-transform: translateY(-50%) translateX(-36.6667%) scale(0.333333) !important;
  -ms-transform: translateY(-50%) translateX(-36.6667%) scale(0.333333) !important;
  -o-transform: translateY(-50%) translateX(-36.6667%) scale(0.333333) !important;
}

.css-1qzevvg {
  display: none !important;
}
