.shadowInput {
    border-radius: 5px;
    background: #F4F4F4;
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.10);
}

.checkboxes {
    gap: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;

    .chkBox {
        display: flex;
        align-items: center;
        gap: 20px;

        input {
            height: 15px;
            width: 15px;
            padding: 5rem !important;
        }

        p {
            color: #000;
            text-align: justify;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.75px;
            text-transform: capitalize;
            width: 100%;

            span {
                color: #000;

                text-align: justify;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 0.75px;
                text-transform: capitalize;
            }
        }


    }

}