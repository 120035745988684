.teacher-container {
    height: auto;
    background: #FFF5C9;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    .t-container {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        align-items: center;
        padding: 8rem 1rem;

        @media screen and (max-width:"1200px") {
            padding: 7rem 1rem;
        }

        @media screen and (max-width:"1024px") {
            padding: 6rem 1rem;
        }

        @media screen and (max-width:"768px") {
            padding: 3rem 1rem;
            gap: 1rem;
        }

        @media screen and (max-width:"480px") {
            padding: 3rem 1rem;
            gap: 0rem;
        }

        .heading {
            font-family: 'darkFont';
            font-weight: 700;
            font-size: 50px;
            line-height: 72px;
            text-align: center;
            z-index: 1;



            @media screen and (max-width:"1450px") {
                font-size: 45px;

            }

            @media screen and (max-width:"1024px") {
                font-size: 40px;
                line-height: 65px;

            }

            @media screen and (max-width:"768px") {
                font-size: 25px;
                line-height: 60px;

            }
        }

        .para {
            font-weight: 400;
            font-size: 30px;
            line-height: 48px;
            text-align: center;
            z-index: 1;


            @media screen and (max-width:"1024px") {
                font-size: 25px;
                line-height: 35px;

            }

            @media screen and (max-width:"768px") {
                font-size: 17px;
                line-height: 27px;

            }
        }

        .t-btn {
            z-index: 1;
            padding-top: 1rem;
        }

        #f-icon {
            display: block;
            border-radius: 50%;
            position: absolute;
            animation: right 5s linear 0s infinite alternate,
                right 3s linear 0s infinite alternate;
            animation-duration: 30s;


            img {
                width: 200px;
                height: 200px;
            }

            @keyframes right {
                from {
                    right: 0;
                }

                to {
                    right: 94%;
                }
            }

            @keyframes top {
                from {
                    top: 0;
                }

                to {
                    top: 83%;
                }
            }
        }

        #s-icon {
            display: block;
            border-radius: 50%;
            position: absolute;
            animation: bottom 5s linear 0s infinite alternate,
                left 3s linear 0s infinite alternate;
            animation-duration: 30s;


            img {
                width: 100px;
                height: 100px;
            }

            @keyframes bottom {
                from {
                    bottom: 0;
                }

                to {
                    bottom: 95%;
                }
            }

            @keyframes left {
                from {
                    left: 0;
                }

                to {
                    left: 82%;
                }
            }
        }

        #t-icon {
            display: block;
            border-radius: 50%;
            position: absolute;
            animation: right 5s linear 0s infinite alternate,
                bottom 3s linear 0s infinite alternate;
            animation-duration: 30s;

            img {
                width: 100px;
                height: 100px;
            }

            @keyframes right {
                from {
                    right: 0;
                }

                to {
                    right: 95%;
                }
            }

            @keyframes bottom {
                from {
                    bottom: 0;
                }

                to {
                    bottom: 82%;
                }
            }
        }

        #forth-icon {
            display: block;

            border-radius: 50%;
            position: absolute;
            animation: top 5s linear 0s infinite alternate,
                left 3.45s linear 0s infinite alternate;
            animation-duration: 30s;


            img {
                width: 200px;
                height: 200px;
            }

            @keyframes left {
                from {
                    left: 0;
                }

                to {
                    left: 94%;
                }
            }

            @keyframes top {
                from {
                    top: 0;
                }

                to {
                    top: 83%;
                }
            }
        }


    }
}