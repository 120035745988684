.loginContainer {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    max-width: 500px;
    border-radius: .6rem;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    margin-top: 2rem;

    .heading {
        font-size: 1.5rem;
        font-weight: 600;
        background-color: #fff5c9;
        padding: 1.5rem;
    }

    .content {
        padding: 1rem 1rem 2rem 1rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;

        p {
            font-style: italic;
        }

        .buttonBox {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 2rem;
        }
    }

}