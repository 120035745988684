.callMainContainer {
    padding-top: 10rem;
    padding: 10rem 2rem 2rem 2rem;
    max-width: 1400px;
    margin: 0 auto;
    font-family: "Poppins", sans-serif !important;

    @media screen and (max-width:375px) {
        padding: 10rem 0rem 2rem 0rem;
     }

    .name {
        font-size: 25px;
        line-height: 30px;
        font-weight: 700;
        color: #fcd117;
        font-style: normal;
        padding: 1rem 0;
    }
    .payBtnMain{
        margin: 1rem 0;
        display: flex;
        justify-content: flex-end;
        .icon{
            color: var(--themeColor);
        }
    }
    .relative{
        margin: 2rem 0;
    }

    .detailMain {
        display: flex;
        gap: 3rem;

        @media screen and (max-width:768px) {
           flex-direction: column;
        }
    }
    .callFormContainer{
        width: 100%;
        textarea {
            width: 100%;
        }
        .paymentCart{
            position: relative;
        }
    }

    @media screen and (max-width:768px) {
        .rccs__card {
            height: 161.873px;
            margin: 0;
            width: 257px;
        }
     }
   
}