.containerMainBlogs {

    .imgContainer {

        background-image: url("../../Assets/Images/blogsPic.png");
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 500px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        object-fit: fill;

        .blogImgText {
            min-height: 400px;
            width: 100%;
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: white;

            .textContainer {
                width: 70%;

                h1 {
                    margin: 10px 0 20px 0;
                    text-align: center;
                    font-size: 35px;
                    font-style: normal;
                    font-weight: 600;
                }

                p {
                    text-align: center;
                    font-size: large;
                    line-height: 25px;
                }
            }

        }

        @media screen and (max-width: 1100px) {
            height: 400px;

            .blogImgText {
                width: 85%;

                .textContainer {
                    width: 100%;

                    h1 {
                        font-size: 25px;
                    }

                    p {
                        font-size: 15px;

                    }
                }
            }
        }

        @media screen and (max-width: 470px) {
            height: 300px;

            .blogImgText {
                width: 95%;

                .textContainer {
                    margin-top: 1.3rem;
                    width: 100%;

                    h1 {
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 20px;
                        margin: 0;
                    }

                    p {
                        margin: 0;
                        color: #FFF;
                        margin-top: .3rem;
                        font-size: 12px;
                        line-height: 17px;
                        font-weight: normal;
                    }
                }
            }
        }


    }

    .blogsContainer {
        padding-bottom: 10rem;
    }

    .reverseFlex {
        @media only screen and (max-width:800px) {
            display: flex;
            gap: 3rem;
        }
    }
}