.primary-heading {

    .heading {
        font-weight: 700;
        font-size: 45px;
        line-height: 54px;
        letter-spacing: 0.05em;
        color: #000000;
        font-family: 'darkFont';
        text-transform: capitalize;



        @media screen and (max-width:"1450px") {
            font-size: 40px;
            line-height: 45px;
            letter-spacing: 0.02em;

        }

        @media screen and (max-width:"1024px") {
            font-size: 30px;
            line-height: 30px;

        }
        @media screen and (max-width:"768px") {
            font-size: 22px;
            line-height: 30px;
            text-transform: uppercase;

        }
    }
}