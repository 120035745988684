.PreLoaderMain {
    height: 100vh;
    width: 100%;
    display: flex;
    // align-items: center;
    padding-top: 10rem;
    justify-content: center;
    .progress{
        color: var(--themeColor);
    }
}