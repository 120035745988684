.navbar-container {
    width: 100%;
    position: absolute;
    box-sizing: border-box !important;
    z-index: 20;

    .navbar {
        width: 100%;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width:"1450px") {
            height: 130px;
            padding: 0 1rem;
        }

        .logo {
            margin-top: -1rem;

            @media screen and (max-width:"768px") {
                width: 80px;
                height: 60px;
                margin-top: -.5rem;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .webmenu {
            display: flex;
            align-items: center;
            gap: 2rem;
            font-size: 20px;

            @media screen and (max-width: "1450px") {
                font-size: 18px;
                gap: 1.5rem;
            }

            @media screen and (max-width: 1020px) {
                display: none;

            }

            a {
                color: black;

                .icon {
                    font-size: 30px;

                    @media screen and (max-width: "1450px") {
                        font-size: 25px;

                    }
                }
            }
            .bagData{
                position: relative;
                .iconBag{
                    cursor: pointer;
                }
                .bookedbooks{
                    position: absolute;
                    top: -5px;
                    right: -12px;
                    border-radius: 50%;
                    font-size: 12px;
                    background-color: red;
                    color: white;
                    min-width: 1.3rem;
                    text-align: center;
                    font-weight: bold;
                }
            }

            a.active {
                color: var(--themeColor) !important;
                font-weight: bold;
            }

        }

        .mobilemenu {
            display: none;

            @media screen and (max-width:"1020px") {
                display: block;
            }

            .icon {
                font-size: 30px;
            }




        }

        .nav-popover {
            .imgBox {
                height: 40px;
                width: 40px;
                cursor: pointer;
                border-radius: 50rem;
                overflow: hidden;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.flex-menu {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    a {
        color: black;

        .icon {
            font-size: 30px;
        }
    }

    a.active {
        color: var(--themeColor) !important;
        font-weight: bold;
    }
    .bagData{
        position: relative;
        .iconBag{
            cursor: pointer;
        }
        .bookedbooks{
            position: absolute;
            top: -5px;
            left: 12px;
            border-radius: 50%;
            font-size: 12px;
            background-color: red;
            color: white;
            min-width: 1.3rem;
            text-align: center;
            font-weight: bold;
        }
    }
}

.ant-popover {

    .popoverHeading {
        color: var(--primaryTextColor);

        .name {
            font-size: 1rem;
            font-weight: bold;
        }

        .skill {
            color: var(--primaryTextColor);
            font-size: .65rem;
        }
    }

    .antPopover {
        display: flex;
        flex-direction: column;
        gap: .8rem;

        .popoverItem {
            padding: .5rem;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 2px;
            cursor: pointer;
        }
    }

}