.teacherDetailMainContainer {
  padding-top: 10rem;
  // padding: 10rem 1.5rem 2rem 4rem;
  width: 90%;
  margin: 0 auto;

  .name {
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;
    color: #fcd117;
    font-style: normal;
    padding: 1rem 0;
  }

  .detailMain {
    display: flex;
    gap: 5rem;

    .tableMain {
      width: 100%;

      .table {
        width: 100%;

        .ant-table-content {
          table {
            border-spacing: 0 0.5rem;
          }
        }

        :where(.css-dev-only-do-not-override-k7429z).ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-k7429z).ant-table-wrapper .ant-table-thead >tr>td {
          background: linear-gradient(90.25deg, #ffc01e 0%, #ffdb7e 99.55%);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
          border-bottom: 0;
        }
        :where(.css-dev-only-do-not-override-k7429z).ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, :where(.css-dev-only-do-not-override-k7429z).ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
          background-color: transparent;
        }

        .ant-table-wrapper::after {
          display: none;
          clear: both;
          content: "";
        }
        .ant-table-wrapper
          .ant-table-tbody
          > tr
          > td
          > .ant-table-wrapper:only-child
          .ant-table,
        :where(.css-dev-only-do-not-override-k7429z).ant-table-wrapper
          .ant-table-tbody
          > tr
          > th
          > .ant-table-expanded-row-fixed
          > .ant-table-wrapper:only-child
          .ant-table,
        :where(.css-dev-only-do-not-override-k7429z).ant-table-wrapper
          .ant-table-tbody
          > tr
          > td
          > .ant-table-expanded-row-fixed
          > .ant-table-wrapper:only-child
          .ant-table {
          margin-inline: 190px -16px;
        }

        .ant-table-tbody > tr {

          // background: #ffffff;
          box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.05);
          border-radius: 0px;
        }

        .tablehead {
          background: linear-gradient(90.25deg, #ffc01e 0%, #ffdb7e 99.55%);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
          border-radius: 5px 5px 0px 0px;
          height: 69px;
          text-align: start;

          .tableRow {
            width: 100%;
          }

          .tableh {
            text-align: start;
          }

          .first {
            padding-left: 4rem;
          }

          .secend {
            width: 60%;
          }
        }

        .tableBody {
          background: #ffffff;
          box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.05);
          border-radius: 0px;
          height: 50px;
          text-align: start;

          .tableBodyRow {
            width: 100%;
            margin-top: 1rem;
          }

          .tabled {
            text-align: start;
          }

          .first {
            padding-left: 4rem;
          }

          .secend {
            width: 60%;
          }
        }
      }
    }

    .ourTeacher {
      width: 20%;
      height: max-content;
      // background: #ffffff;
      box-shadow: 2px 5px 4px rgba(0, 0, 0, 0.15);
      border-radius: 2px;
      padding: 2rem;
      gap: 1rem;

      .teach {
        padding-top: 0.5rem;
      }

      .head {
        padding-bottom: 2rem;
        font-size: 25px;
        font-weight: 400;
        font-style: normal;
        line-height: 30px;
        color: black;
      }

      .teacherNameHead {
        font-size: 12px;
        color: gray;
        font-weight: 400;
        line-height: 14px;
      }

      .teacherName {
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
        line-height: 18px;
        color: #323232;
      }

      .ant-rate-star {
        margin-inline-end: 2px;
      }

      .joinBtn {
        margin-top: 2rem;
        color: #323232;
        border: transparent;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        background: #fcd117;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 2px;
        height: 50px;
        // width: 119px;
        align-self: center;
        margin-left: 1.5rem;
      }
    }
  }
}
