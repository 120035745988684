.PrayerBarContainer {
  height: 57px;

  .toggleMarquee {
    position: absolute;
    right: 0;
    top: 5px;
    bottom: 5px;
    z-index: 30;
    color: #fff;
    width: 40px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 5px;
    background-color: var(--themeColor);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

  }

  .PrayerBar {
    background-color: rgba(252, 209, 23, 0.5);
    backdrop-filter: blur(5px);
    z-index: 20;

    .flex {
      height: 3.5rem;
    }

  }
}