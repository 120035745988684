.booksDetailPage {
    max-width: 1483px;
    margin: auto;

    .bookDetailImg {
        border-radius: 10px;
        object-fit: cover;
    }

    .detailDescriptionBook {
        margin-top: 10px;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.03em;
        text-align: left;
        color: #323232;
    }

    .bookTitle {
        font-size: 30px;
        font-weight: 600;
    }

    .publisher {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
    }

    .author {
        margin-top: 20px;
        font-size: 32px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
    }


    .priceTag {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
    }

    .price {
        margin-top: 5px;
        display: flex;
        align-items: center;
        font-size: 30px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
    }

    .rate {}


    .bookReturn {
        display: flex;
        gap: 10px;
        color: #808080;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

    }

    .reviewCards {
        margin-top: 80px;
        display: flex;
        justify-content: start;
        align-items: center;
        flex-wrap: wrap;
        gap: 45px;

        .fCard {
            border: 6.24px solid white;
            display: flex;
            box-shadow: 62.37810134887695px 43.6646728515625px 124.7562026977539px 0px #00000014;
            gap: 40.9px;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            padding: 20px 29.9px;
            min-height: 200px;
            max-width: 576px;
            background: #FFF9E1;
            border-radius: 49.9px;

            .heading {
                min-width: 192px;

                h1 {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    font-size: 90px;
                    font-weight: 800;
                    line-height: 96px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #323232;
                }

                p {
                    width: 100%;
                    padding: 7.5px 18px;
                    background: #323232;
                    color: #fff;
                    border-radius: 52.5px;
                    text-align: center;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 15px;
                    letter-spacing: 0em;
                }
            }

            .reviews {
                max-width: 234px;
                display: flex;
                flex-direction: column;
                align-items: start;
                gap: 37px;

                .ratesBars {
                    max-height: 115px;
                    width: 100%;
                    display: flex;

                    .numbers {
                        display: flex;
                        flex-direction: column-reverse;
                        gap: 4.5px;
                        color:
                            #6A6A6A;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 18px;
                        letter-spacing: 0em;
                    }

                    .rates {
                        display: flex;
                        flex-direction: column-reverse;
                        gap: 4.2px;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 18px;
                        letter-spacing: 0em;
                    }

                    .progress {
                        height: 100%;
                        width: 100%;
                        color: #323232;
                        display: flex;
                        align-items: start;
                        justify-content: start;
                        flex-direction: column;
                        gap: 14.5px;

                        .progressBar {
                            height: 0px;
                            background: #000;
                            color: #000;

                        }

                        .ant-progress-bg {
                            background: linear-gradient(269.85deg, #FCD117 0%, #FFE576 65.66%);
                        }
                    }


                }

                .btn {
                    height: 30px;
                    width: 192px;
                    background: none;
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    gap: 12px;
                    font-size: 19px;
                    font-weight: 500;
                    line-height: 28px;
                    color: var(--themeColor);
                    transition: ease-in-out .2s;

                    &:hover {
                        // border: 1px solid rgba(128, 128, 128, 0.397);
                        transform: scale(1.02);
                    }
                }
            }
        }

        .lCard {
            border: 6.24px solid white;
            box-shadow: 62.37810134887695px 43.6646728515625px 124.7562026977539px 0px #00000014;
            gap: 49.9px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            padding: 20px 29.9px;
            min-height: 200px;
            max-width: 617px;
            background: #FFF9E1;
            border-radius: 49.9px;

            .msgLogo {

                .logo {
                    color: #1E1E1E;
                    width: 120px;
                    height: 120px;

                }
            }

            .commentDetails {
                max-width: 331px;
                height: 176px;
                display: flex;
                flex-direction: column;
                gap: 24.95px;

                h2 {
                    font-size: 62px;
                    font-weight: 800;
                    line-height: 62px;
                    letter-spacing: 0em;
                    text-align: left;
                    color:
                        #1E1E1E;
                }

                p {
                    font-size: 22px;
                    font-weight: 400;
                    line-height: 31px;
                    letter-spacing: 0em;
                    color: #868686;
                }

                .btn {
                    height: 30px;
                    width: 100%;
                    background: none;
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    gap: 12px;
                    font-size: 19px;
                    font-weight: 500;
                    line-height: 28px;
                    color: var(--themeColor);
                }
            }

        }

    }

    .allComments {
        margin-top: 80px;
        max-width: 1271px;
        min-height: 260px;
        display: flex;
        flex-direction: column;
        gap: 60px;



        .title {
            font-size: 2rem;
            font-weight: bold;
        }

        .comment {
            width: 100%;
            height: 100%;
            display: flex;
            gap: 25px;
            min-width: 271px;

            .img {
                min-width: 59px;
                max-width: 59px;
                max-height: 59px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: fill;
                    border-radius: 50%;
                }
            }

            .details {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .heading {
                    h2 {
                        font-weight: 800;
                        font-size: 26px;
                        color: #323232;
                        letter-spacing: 0em;
                        text-align: left;
                    }

                    .rate {
                        font-size: 18px;
                    }
                }
            }

            .para {
                max-width: 1170px;

                P {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 30px;
                    letter-spacing: 0.03em;
                    text-align: left;
                    color: #323232;
                }

            }
        }


    }

}

.secondary-button {
    .ant-btn-default {
        background-color: transparent !important;
    }

    button {
        width: 150px;
        height: 50px;
        font-size: 20px;
        cursor: pointer;
        color: var(--dark);
        font-family: 'lightFont';
        font-weight: 500;
        border-radius: 5px;
        background: transparent;
        transition: all 0.3s ease;
        position: relative;
        box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
            7px 7px 20px 0px rgba(0, 0, 0, .1),
            4px 4px 5px 0px rgba(0, 0, 0, .1);

        @media screen and (max-width:"1450px") {
            width: 130px;
            height: 44px;

        }

        @media screen and (max-width:"768px") {
            width: 100px;
            height: 44px;
            font-size: 13px;


        }
    }

    .sec-btn {
        line-height: 42px;
        padding: 0;
        border: none;

        &:hover {
            box-shadow: none;
        }
    }


    .sec-btn:before,
    .sec-btn:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 2px;
        width: 0;
        box-shadow:
            -1px -1px 5px 0px #fff,
            7px 7px 20px 0px #0003,
            4px 4px 5px 0px #0002;
        transition: 400ms ease all;
    }

    .sec-btn:after {
        right: inherit;
        top: inherit;
        left: 0;
        bottom: 0;
    }

    .sec-btn:hover:before,
    .sec-btn:hover:after {
        width: 100%;
        transition: 800ms ease all;
    }
}