.search-popover {
  height: 18rem;
  width: 33rem;
  overflow-y: scroll;
  padding-right: 1rem;

  .books {
    color: gray;
    font-weight: bold;
  }

  .userMain {
    display: flex;
    justify-content: space-between;
    color: gray;
    cursor: pointer;
    gap: 1rem;
    padding-top: 0.3rem;

    // width: 100%;
    .title {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      align-items: center;
    }

    img {
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .bookMain {
    color: gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding-top: 0.3rem;
    width: 100%;
    cursor: pointer;

    .transactionStatus {
      color: rgb(85, 85, 0);
      text-transform: uppercase;
      background-color: #ebebeb;
      padding: 4px;
      border-radius: 5px;
    }

    .title {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      align-items: center;
    }

    img {
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .moreBtn {
    color: gray;
    border: 1px solid gray;
    cursor: pointer;
    align-self: center;
    margin: 1rem;
  }

  .moreBtn:hover {
    color: black;
    background-color: #d1d1d1;
  }
}

.searchBarMain {
  width: 100%;

  .inputfield {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    position: relative;
    height: 55px;

    input {
      border-radius: 5px;
      width: 100%;
      height: 100%;
      padding: 0.5rem 30px;
      font-size: 1rem;
      background: #ffffff;
      box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
      font-family: "Montserrat", sans-serif;
      transition: 0.5s;
      color: grey;

      @media screen and (max-width: 768px) {
        padding: 0.5rem 20px;
        font-size: 16px;
      }

      &:hover {
        letter-spacing: 2px;
      }
    }

    button {
      position: absolute;
      right: 0;
      padding: 0.5rem 55px;
      background: #fcd117;
      top: 0;
      bottom: 0;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      transition: 0.5s;
      cursor: pointer;

      @media screen and (max-width: "1450px") {
        padding: 20px 50px;
      }

      @media screen and (max-width: "768px") {
        padding: 15px 30px;
      }

      .icon {
        width: 30px;
        height: 30px;

        @media screen and (max-width: "1450px") {
          width: 25px;
          height: 25px;
        }

        @media screen and (max-width: 768px) {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}