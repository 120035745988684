.footer-container {
    // color: #fff;
    // background-color: rgba(32, 31, 31, 0.849);
    background-color: #101820;
    font-family: "Poppins", sans-serif;

    .footer {
        padding: 7rem 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid #fff;

        .flex-footer {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 11rem;

            // @media screen and (max-width:"2560px") {
            //     gap: 13rem;
            // }

            @media screen and (max-width:"1024px") {
                gap: 4rem;
            }

            .f-bio {
                margin-top: -76px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                color: white;
                font-size: 13px;

                @media screen and (max-width:"720px") {
                    margin-top: 2rem;
                }

                .logo {
                    max-width: 85%;
                }

                .f-para {
                    text-align: center;
                    max-width: 307px;
                    font-weight: 300;
                    font-size: 17px;
                    line-height: 24px;
                    letter-spacing: 0.02em;
                    text-transform: capitalize;

                    @media screen and (max-width:"768px") {
                        font-size: 18px;

                    }
                }

                .f-logo {
                    display: flex;
                    gap: 1rem;
                    font-size: 8px;

                    .rounded {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .fb {
                        padding: .5rem;
                        border-radius: 50%;

                        &:hover {
                            background-color: #3b5998;

                            .icon {
                                color: #fff;
                            }
                        }
                    }

                    .insta {
                        padding: .5rem;
                        border-radius: 50%;

                        &:hover {
                            background-image: linear-gradient(to bottom right, #F58529, #ED4C67, #E1306C, #C13584, #833AB4, #5851DB);
                        }
                    }

                    .twitter {
                        padding: .5rem;
                        border-radius: 50%;

                        &:hover {
                            background-color: #fff;

                            .icon {
                                color: #1DA1F2;
                            }
                        }
                    }

                    .icon {
                        font-size: 20px;
                        color: #fff;
                    }
                }
            }

            .f-contact {
                display: flex;
                flex-direction: column;
                gap: 2.5rem;

                .c-heading {
                    font-weight: 500;
                    font-size: 25px;
                    line-height: 20px;
                    text-transform: capitalize;
                    color: var(--themeColor);

                }

                .c-details {
                    color: white;
                    font-size: 13px;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;


                    .c-info {
                        display: flex;
                        gap: 1rem;
                        text-align: center;
                        align-items: center;

                        .icon {
                            font-size: 8px;
                            width: 20px;
                            height: 20px;
                            fill: var(--themeColor);
                        }
                    }



                }
            }

            .footer-menu {
                display: flex;
                flex-direction: column;
                gap: 3.5rem;

                .f-heading {
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 36px;
                    text-transform: capitalize;
                    color: var(--themeColor);
                }

                .f-details {
                    display: flex;
                    flex-direction: column;
                    gap: 1.8rem;

                    .f-info {
                        display: flex;
                        gap: 1rem;
                        align-items: center;

                        .icon {
                            width: 22px;
                            height: 8px;
                            background: #FCD117;
                        }
                    }

                }
            }
        }
    }

    // .copyright {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     gap: 1rem;
    //     padding-bottom: 6.25rem;
    //     padding-top: 1.25rem;

    //     .icon {
    //         height: 25px;
    //         width: 25px;
    //     }

    //     .rights {
    //         font-weight: 400;
    //         font-size: 13px;
    //         line-height: 24px;
    //         letter-spacing: 0.02em;
    //         text-transform: capitalize;
    //         color: #FFFFFF;
    //     }
    // }
}

.copyRightsPara {
    gap: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}