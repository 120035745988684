.aboutCourses-container {
    padding: 7rem 0;
    background-color: #FFF5C9;

    @media screen and (max-width:"1450px") {
        padding: 5rem 0rem;

    }

    @media screen and (max-width:"1024px") {
        padding: 3rem 0rem;

    }

    .course-section {
        display: flex;
        flex-direction: column;
        gap: 5rem;

        @media screen and (max-width:"1450px") {
            gap: 3rem;

        }

        @media screen and (max-width:"1024px") {}

        .header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;

            .event {
                color: var(--themeColor);
                font-size: 20px;
                font-family: "darkFont";
            }

        }

        .courses {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 3rem;
            padding-left: 2rem;
            padding-right: 2rem;
            @media screen and (max-width:"768px") {
                justify-content: center;
                padding: 1.5rem;
            }

            .course {
                max-width: 400px;
                display: flex;
                gap: 1rem;
                flex-direction: column;

                @media screen and (max-width:"768px") {
                    align-items: center;
                    text-align: center;
                }

                .logo {
                    width: 55px;
                    height: 45px;

                    img {
                        object-fit: contain;
                        width: 100%;
                        height: 100%;
                    }
                }

                .heading {
                    font-size: 30px;
                }

                .para {
                    color: #777;
                    line-height: 1.8em;
                    font-size: 16px;
                    font-weight: 400;

                }


            }

        }

    }



}