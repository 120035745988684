.courses-container {
  padding: 4rem 0;
  background-color: #F5F5F5;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width:768px) {
    // padding-bottom: 1rem;
  }

  .heading {
    // width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2rem;

    @media screen and (max-width:1024px) {
      padding-left: 1rem;
      padding-right: 1rem;

    }

    @media screen and (max-width:768px) {
      flex-direction: column;

    }

    .courses-buttons {
      display: flex;
      gap: 1.5rem;

      // .ant-select-selector {
      //     width: 100%;
      //     height: 32px;
      //     padding: 0 11px;
      //     font-size: 20px;
      //     border: none !important;
      //     box-shadow: none !important;
      //     background: transparent;
      //     color: black !important;

      //     @media screen and (max-width:1450px) {
      //         font-size: 18px;

      //     }

      //     @media screen and (max-width:768px) {
      //         font-size: 13px;
      //     }

      // }

      // .ant-select-arrow {
      //     color: #000000a2 !important;
      //     font-family: 'lightFont' !important;
      //     top: 55% !important;
      //     font-size: 18px !important;

      //     @media screen and (max-width:768px) {
      //         font-size: 13px !important;
      //     }
      // }

    }
  }

  .content {
    text-align: center;
    margin-bottom: 30px;
  }

  .header {
    font-size: 42px;
  }

  .img {
    display: flex;
    margin: 0 auto;
    height: 36vh;
    width: 70%;
    border-radius: 5%;
  }

  .container {
    margin: 0 auto;
    width: 100%;
    border-color: white;
  }

  .title {
    margin-left: 50px;
    width: 70%;
    font-size: 20px;
    padding: 20px;
    border-radius: 5px;
  }

  .description {
    margin: 20px auto;
    width: 80%;
    font-size: 15px;
    margin: 20px auto;
  }

  // .carousel {
  //     .card {
  //         margin: 5rem 0;
  //         display: flex;
  //         flex-direction: column;
  //         gap: 1rem;
  //         align-items: center;
  //         padding: 1rem;
  //         width: 378px;
  //         box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.2);

  //         @media screen and (max-width:"1500px") {
  //             width: 348px;
  //         }

  //         @media screen and (max-width:"768px") {
  //             margin: 4rem auto;
  //         }

  //         .card-img {
  //             img {
  //                 width: 100%;
  //                 height: 100%;
  //             }
  //         }

  //         .card-bio {
  //             display: flex;
  //             flex-direction: column;
  //             gap: .5rem;
  //             padding: 0 1rem;

  //             .card-rate {
  //                 width: 100%;
  //                 display: flex;
  //                 justify-content: space-between;

  //                 .rate {
  //                     display: flex;
  //                     align-items: center;
  //                     gap: .5rem;
  //                 }

  //             }

  //             .card-para {
  //                 text-align: justify;
  //                 max-width: 325px;
  //                 padding: 0.5rem 0 1.2rem 0;
  //             }
  //         }


  //         .card-border {
  //             width: 109%;
  //             border: 1px solid #8d8383;
  //         }

  //         .card-detail {
  //             height: 100%;
  //             width: 100%;
  //             display: flex;
  //             justify-content: space-between;
  //             align-items: center;

  //             .card-profile {
  //                 display: flex;
  //                 align-items: center;
  //                 gap: .5rem;

  //                 img {
  //                     height: 54px;
  //                     width: 54px;
  //                 }
  //             }

  //             .card-price {
  //                 font-size: 18px;

  //                 span {
  //                     color: var(--themeColor);
  //                 }
  //             }
  //         }
  //     }
  // }

  // .sliderArrows {
  //     width: 100%;
  //     display: flex;
  //     align-items: center;

  //     .rightArrowBtn,
  //     .leftArrowBtn {
  //         display: flex;
  //         align-items: center;
  //         gap: 1rem;
  //         border-radius: 3px;
  //         padding: 1rem;
  //         background: #FFFFFF;
  //         box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

  //         @media screen and (max-width:1450px) {
  //             .icon {
  //                 width: 48px;
  //                 height: 38px;
  //             }

  //             padding: .5rem;
  //         }

  //     }

  //     .progressBar {
  //         width: 100%;
  //         margin: 0 1rem;
  //     }
  // }

}