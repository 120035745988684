.ContactContainer {
    padding: 11rem 0 4rem 0;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    .flexContact {
        width: 100%;
        display: flex;
        gap: 3rem;

        @media screen and (max-width:"1440px") {
            flex-direction: column;
            padding: 1rem;

        }

        .contact {
            width: -webkit-fill-available;
            padding: 60px;
            box-shadow: 0 5px 15px 0 rgba(68, 68, 68, .1);
            border-radius: 5px;
            @media screen and (max-width:"425px") {
                padding: 20px;
    
            }
            


            .heading {
                font-size: 1.875rem;
                // font-size: 45px;
                font-weight: bold;
                margin: 0 0 1em;
                text-align: center;
                font-family: 'darkFont';
            }

            .inputFields {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                align-items: center;

                .flexField {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    gap: 1rem;

                    .fieldWidth{
                        width: 100%;
                    }
                }

                .input {
                    font-size: 17px;
                    background: transparent;
                    width: 100%;
                    height: 50px;
                    border: 0;
                    border-bottom: 1px solid #d0d4df;
                    border-radius: 0;
                    box-shadow: none !important;
                    padding-left: 0;
                    color: #525252;
                }
                
                
                
                textarea {
                    font-size: 17px;
                    
                    background: transparent;
                    width: 100%;
                    height: 120px;
                    border: 0;
                    border-bottom: 1px solid #d0d4df;
                    border-radius: 0;
                    box-shadow: none !important;
                    padding: 2rem 0;
                    color: #525252;
                    position: relative;
                }
                .errorMessage {
                    height: 25px;
                    // position: absolute;
                    text-align: start;
                    bottom: -30px;
                    left: 5px;
                    font-size: .9rem;
                    color: red;
                }

                .button {
                    padding-top: 2.5rem;
                }
            }
        }

        .map {
            box-shadow: 0 5px 15px 0 rgba(68, 68, 68, .1);
            width: -webkit-fill-available;
            height: 40rem;

            .iframe {
                width: 100%;
                height: 100%;
                border-radius: 5px;
            }
        }

    }

    .contactCards {
        width: 100%;
        display: flex;
        gap: 2rem;

        @media screen and (max-width:"1440px") {
            flex-direction: column;
            padding: 1rem;

        }

        .card {
            width: 100%;
            display: flex;
            flex-direction: column;
            box-shadow: 0 5px 15px 0 rgba(68, 68, 68, .1);
            padding: 50px 40px;
            gap: 2rem;
            border: 1px solid rgb(50 50 50 / 2%);


            .heading {
                // font-size: 1.875rem;
                font-size: 20px;
                font-weight: bold;
                font-family: 'darkFont';

                @media screen and (max-width:"768px") {
                    font-size: 17px;
        
                }

            }

            .flexInfo {
                display: flex;
                gap: 1rem;
                align-items: center;
                

                .infoIcon {
                    width: 90px;
                    height: 90px;
                    border-radius: 50%;
                    box-shadow: 0 10px 40px 0 rgba(50, 50, 50, .1);
                    background-color: var(--themeColor);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .icon {
                        font-size: 40px;
                    }
                }

                .infoheading {
                    color: #666;
                    font-size: 18px;
                    font-weight: 600;
                }

            }
        }

    }
}