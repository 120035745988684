.home-container {
    background-color: #FFF5C9;

    .flex-home {
        padding-top: 7rem;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width:"1024px") {
            height: 100%;
            flex-direction: column;
            padding: 5rem 20px 4rem 20px;
            gap: 5rem;
        }

        .content-section {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            @media screen and (max-width:"1450px") {
                gap: .5rem;

            }

            .heading {
                max-width: 522px;
                font-style: normal;
                font-weight: 700;
                font-size: 60px;
                line-height: 73px;
                font-family: 'darkFont';
                color: #000000;

                @media screen and (max-width: "1450px") {
                    max-width: 478px;
                    font-size: 48px;
                    line-height: 50px;
                }

                @media screen and (max-width: "1024px") {
                    max-width: 590px;
                    font-size: 45px;
                    line-height: 55px;
                    text-align: center;
                    padding-top: 8rem;

                }

                @media screen and (max-width: "768px") {
                    max-width: 590px;
                    font-size: 25px;
                    line-height: 30px;
                    text-align: center;
                    padding: 5rem 2rem 0rem 2rem;
                }
            }

            .para {
                max-width: 700px;
                font-size: 23px;
                font-weight: 400;
                line-height: 30px;
                color: #000000;
                text-align: justify;
                font-family: 'lightFont';

                @media screen and (max-width: "1450px") {
                    max-width: 590px;
                    font-size: 20px;
                    line-height: 25px;
                    color: #000000;
                    font-family: "lightFont";
                }

                @media screen and (max-width: "768px") {
                    font-size: 16px;
                    line-height: 27px;
                }

            }

            .input-field {
                margin-top: 1.5rem;
                display: flex;
                align-items: center;
                position: relative;
                height: 55px;

                input {
                    border-radius: 5px;
                    width: 100%;
                    height: 100%;
                    padding: .5rem 30px;
                    font-size: 1rem;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
                    font-family: 'Montserrat', sans-serif;
                    transition: .5s;
                    color: grey;

                    @media screen and (max-width: 768px) {
                        padding: .5rem 20px;
                        font-size: 16px;
                    }


                    &:hover {
                        letter-spacing: 2px;
                    }
                }

                button {
                    position: absolute;
                    right: 0;
                    padding: .5rem 55px;
                    background: #FCD117;
                    top: 0;
                    bottom: 0;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5px;
                    transition: .5s;
                    cursor: pointer;

                    @media screen and (max-width: "1450px") {
                        padding: 20px 50px;
                    }

                    @media screen and (max-width: "768px") {
                        padding: 15px 30px;
                    }

                    .icon {
                        width: 30px;
                        height: 30px;

                        @media screen and (max-width: "1450px") {
                            width: 25px;
                            height: 25px;
                        }

                        @media screen and (max-width: 768px) {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }

        }

        .video-section {
            position: relative;

            .image-border {
                border: 10px solid #FCD117;
                border-radius: 41px;
                width: 350px;
                height: 530px;

                @media screen and (max-width: "1450px") {
                    border: 8px solid #FCD117;
                    border-radius: 41px;
                    width: 250px;
                    height: 380px;
                }

                @media screen and (max-width: "768px") {
                    width: 250px;
                    height: 350px;
                }

            }

            img {
                position: absolute;
                top: -30px;
                right: 33px;
                width: 360px;
                height: 530px;

                @media screen and (max-width: "1450px") {
                    top: -20px;
                    right: 25px;
                    width: 250px;
                    height: 380px;
                }

                @media screen and (max-width: 768px) {
                    right: 25px;
                    width: 250px;
                    height: 350px;
                }
            }

            .video-btn {
                width: 100%;
                height: 100%;
                position: absolute;
                top: -50px;
                left: -40px;
                display: flex;
                align-items: center;
                justify-content: center;

                .button {
                    border-radius: 50%;
                    cursor: pointer;
                    position: relative;
                    z-index: 5;
                    padding: 25px;

                    @media screen and (max-width:"1450px") {
                        padding: 20px;
                    }

                    @media screen and (max-width:"768px") {
                        padding: 20px;
                    }

                    .icon {
                        width: 35px !important;
                        height: 35px !important;
                        padding-left: 7px !important;

                        @media screen and (max-width:"1450px") {
                            width: 30px !important;
                            height: 30px !important;
                        }
                    }

                    .span {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        left: 50%;
                        top: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        display: block;
                        width: 70px;
                        height: 70px;
                        background: #fff;
                        border-radius: 50%;
                        animation: pulse-border 1500ms ease-out infinite;

                        @media screen and (max-width:"1450px") {
                            width: 70px !important;
                            height: 70px !important;
                        }

                        @media screen and (max-width:"768px") {
                            width: 60px !important;
                            height: 60px !important;
                        }

                        @keyframes pulse-border {
                            0% {
                                transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
                                opacity: 1;
                            }

                            100% {
                                transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
                                opacity: 0;
                            }
                        }

                    }

                    .second {
                        width: 90px;
                        height: 90px;

                        @media screen and (max-width:"1450px") {
                            width: 80px !important;
                            height: 80px !important;
                        }

                        @media screen and (max-width:"768px") {
                            width: 70px !important;
                            height: 70px !important;
                        }
                    }

                    .third {
                        width: 110px;
                        height: 110px;

                        @media screen and (max-width:"1450px") {
                            width: 80px !important;
                            height: 80px !important;
                        }

                        @media screen and (max-width:"768px") {
                            width: 70px !important;
                            height: 70px !important;
                        }
                    }

                }
            }
        }

    }

}