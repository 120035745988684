// MuiModal-backdrop css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop
.MuiBackdrop-root {
    // background: none;
    // z-index: 1000 !important;

}

.MuiDrawer-root {
    z-index: 999 !important;

}


.MuiPaper-root {
    width: 23rem;
    font-family: 'Poppins', sans-serif !important;

}


.SideBarMainContainer {

    .sideBarBtn {
        border: none;
        box-shadow: none;
        // color: black;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        text-align: justify;
        text-transform: capitalize;
        padding: 0;
        text-align: center;
        background-color: transparent !important;
    }

    .sideBarBtn:hover {
        color: black
    }

    .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
        color: var(--dark);
        border-color: transparent;
    }
}

.mainSideBar {
    display: grid;
    grid-template-rows: 73% 22%;
    height: 100vh;

    .SideBarContainerupper {
        overflow-y: scroll;

        .nothing {
            padding: 1rem;
        }
    }

    .header {
        text-align: center;
        background-color: var(--themeYellowColor);
        padding: 1rem;
        font-size: 18px;
        display: flex;
        justify-content: space-between;

        .closeBtn {
            font-size: 16px;
            cursor: pointer;
        }

    }



    .bookContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 11rem;
        border-bottom: 1px solid rgb(219 218 218);
        gap: 1rem;
        margin: 0.1rem 0.7rem;

        img {
            border-radius: 5px;
            height: 100px;
            width: 90px;
            object-fit: cover;
            box-shadow: 0px 0px 5.44186px rgba(0, 0, 0, 0.25);

        }

        .bookMain {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 14rem;

            .ant-btn-default {
                background-color: transparent !important;
            }

            .ant-btn-default:hover {
                background-color: #fcd117 !important;
            }

            .bookDetail {
                font-size: 15px;


            }

            .title {
                font-size: 18px;
                font-weight: 800;
                text-transform: capitalize;
            }

            .paraDetail {
                padding: 3px 0;
            }

            .flexLine {
                display: flex;
            }

            .delBtn {
                position: absolute;
                right: 0px;
                top: -10px;
                font-size: 16px;
                color: var(--dark);
                border-radius: 2px;
                border-color: transparent;
                cursor: pointer;
                padding: 0px 3px;
                height: auto;
                box-shadow: 0 0px 0 rgba(0, 0, 0, 0)
            }

            .ant-btn-default:hover {
                background-color: transparent !important;
            }

            .delBtn:hover {
                background-color: transparent;
                // transform: scale(1.1);

            }

        }
    }

    .SideBarFooter {

        padding: 0 4rem;

        .totalPrice {
            display: flex;
            justify-content: space-between;
            padding: 5px 0;
            align-items: center;

            .price {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: bold;
                font-size: 25px;
            }
        }

        .fotterBtn {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .ant-btn-default {
                background-color: transparent !important;
                box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.1);

            }

            .ant-btn-default:hover {
                background-color: #fcd117 !important;
            }

            .fBtn {
                border: transparent;
                color: black;
                background: #FCD117;
                border-radius: 5px;
                padding: 0;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                font-weight: 400;
            }
        }
    }
}