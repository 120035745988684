.class-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4rem 1rem;

    .container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 3rem;

        @media screen and (max-width:"1024px") {
            flex-direction: column;
            gap: 3rem;
        }

        @media screen and (max-width:"768px") {
            gap: 2rem;
            padding-top: 0rem;

        }

        .data-section {
            padding: 3rem 0 2rem 0;
            display: flex;
            flex-direction: column;
            gap: 3rem;

            @media screen and (max-width:"1450px") {
                gap: 2rem;
            }

            @media screen and (max-width:"768px") {
                padding: 0rem 0 2rem 0;
                gap: 1.5rem;
            }

            .para {
                max-width: 580px;
                font-weight: 400;
                font-size: 22px;
                line-height: 30px;
                text-align: justify;
                color: #000000;

                @media screen and (max-width:"1450px") {
                    font-size: 20px;
                    max-width: 500px;
                }

                @media screen and (max-width:"768px") {
                    font-size: 16px;
                    line-height: 27px;
                    max-width: 500px;
                }

            }

            .flex-publish {
                padding-left: 2rem;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                @media screen and (max-width:"1450px") {
                    padding-left: 1rem;
                }

                .publish {
                    display: flex;
                    gap: 1rem;
                    align-items: center;

                    .ant-checkbox .ant-checkbox-inner {
                        box-sizing: border-box;
                        position: relative;
                        top: 0;
                        inset-inline-start: 0;
                        display: block;
                        width: 20px;
                        height: 20px;
                        direction: ltr;
                        background-color: #ffffff;
                        border: 1px solid var(--themeColor);
                        border-radius: 0px;
                        border-collapse: separate;
                        transition: all 0.3s;
                    }

                    .box {
                        width: 20px;
                        height: 20px;
                    }

                    .heading {
                        font-weight: 400;
                        font-size: 22px;
                        line-height: 26px;

                        @media screen and (max-width:"1450px") {
                            font-size: 20px;
                        }

                        @media screen and (max-width:"1450px") {
                            font-size: 16px;
                        }
                    }
                }

            }

            .classes-btn {
                padding-left: 8rem;

                @media screen and (max-width:"1200px") {
                    padding-left: 6rem;
                }

            }
        }

        .image-section {
            position: relative;

            img {
                width: 470px;

                @media screen and (max-width:"1450px") {
                    width: 355px;
                }

                @media screen and (max-width:"768px") {
                    width: 260px;
                }

                @media screen and (max-width:"500px") {
                    width: 230px;
                    padding-left: 1rem;
                }
            }

            .muslim-man {
                img {
                    width: 500px;
                    position: absolute;
                    bottom: 3px;
                    right: 50px;

                    @media screen and (max-width:"1450px") {
                        right: 40px;
                        width: 380px;
                    }

                    @media screen and (max-width:"768px") {
                        width: 285px;
                        right: 30px;
                    }

                    @media screen and (max-width:"500px") {
                        width: 250px;
                        right: 30px;
                    }


                }
            }

        }

    }
}