.about-container {
    // background-color: #F5F5F5;
    padding: 12rem 0 7rem 0;

    @media screen and (max-width:"1450px") {
        padding: 10rem 0rem 5rem 0rem;
    }

    @media screen and (max-width:"1024px") {
        padding: 8rem 0rem 3rem 0rem;
    }

    .seeking-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width:"1024px") {
            flex-direction: column;
            gap: 3rem;
        }

        .left-img {
            height: 647px;
            width: 450px;

            @media screen and (max-width:"1450px") {
                height: 550px;
                width: 400px;
            }

            @media screen and (max-width:"490px") {
                width: 100%;
                height: auto;
                object-fit: contain;
            }

            img {
                height: 100%;
                width: 100%;
            }
        }

        .right-content {
            max-width: 700px;
            display: flex;
            flex-direction: column;
            gap: 2.5rem;
            letter-spacing: 0.05em;

            @media screen and (max-width:"1450px") {
                max-width: 500px;
                gap: 2rem;

            }

            @media screen and (max-width:"768px") {
                gap: 1rem;
                padding: 1.5rem;
            }

            .about {
                margin-bottom: -18px;
                font-size: 22px;
                color: var(--themeColor);
                font-family: 'darkFont';

                @media screen and (max-width:"768px") {
                    margin-bottom: -10px;


                }
            }

            .para {
                font-size: 20px;
                text-align: justify;

                @media screen and (max-width:"768px") {
                    font-size: 18px;
                }
            }

            .about-bio {
                display: flex;
                align-items: center;
                gap: 1.5rem;

                @media screen and (max-width:"768px") {
                    gap: .7rem;
                }

                .videoButton {
                    height: 60px;
                    width: 60px;
                    background-color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-left: 8px;
                    border-radius: 50%;
                    box-shadow: 0 2px 12.75px 2.25px rgb(51 100 219 / 15%);

                    @media screen and (max-width:"768px") {
                        height: 40px;
                        width: 40px;
                        padding-left: 4px;
                    }


                    .icon {
                        height: 27px;
                        width: 27px;

                        @media screen and (max-width:"768px") {
                            height:15px;
                            width: 15px;
                        }

                    }

                }

                .link {
                    color: var(--themeColor);
                    font-size: 20px;
                    font-family: 'darkFont';
                    text-decoration: underline;
                    @media screen and (max-width:"768px") {
                        font-size: 14px;
                    }
    
                }

            }

        }
    }
}