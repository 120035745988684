.sidebarContainer {
    display: flex;
    flex-direction: column;
    // padding: .5rem 2rem;
    margin-bottom: 30px;
    gap: 3rem;
    border-radius: 5px;

    @media only screen and (max-width:768px) {
        margin: 0;
        // border: 1px solid red;
        background: inherit !important;
    }


    @media screen and (max-width:"1024px") {
        padding: 0.5rem 2rem 2.5rem;
    }

    .profileCard {
        display: flex;
        flex-direction: column;
        border-radius: .3rem;
        overflow: hidden;

        .card {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            padding: 2.5rem;
            align-items: center;
            justify-content: center;
            background-color: var(--lightYellow);

            .imgBox {
                width: 170px;
                height: 170px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .details {
                display: flex;
                flex-direction: column;
                gap: .5rem;
                text-align: center;

                .name {
                    font-size: 1.5rem;
                    font-weight: 700;
                }
            }
        }

        .socialLinks {
            display: flex;
            gap: .7rem;
            justify-content: center;
            background-color: var(--themeColor);
            padding: 1rem;
            font-size: 1.1rem;

            .icon {
                cursor: pointer;
            }
        }
    }

    .searchBox {
        margin-top: 2rem;
        width: 100%;
        border: none;
        display: flex;
        border-radius: .2rem;
        // overflow: hidden;


        @media only screen and (max-width:768px) {
            margin: .4rem 0;
            margin: 0;
            max-width: 350px;
            box-shadow: 3px 5px 15px 4px rgba(0, 0, 0, 0.1);
        }

        input {
            width: 100%;
            padding: .8rem;
        }

        .imgBox {

            padding: .5rem 1.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--themeColor);
            cursor: pointer;
            font-size: 1.4rem;

            @media only screen and (max-width:900px) {
                padding: .5rem 1rem;

            }
        }
    }

    .categoryBox {
        display: flex;
        flex-direction: column;
        gap: .8rem;
        color: #505050;

        @media screen and (max-width:768px) {
            display: none;
        }

        .title {
            font-size: 1.2rem;
            font-weight: 700;
        }

        // .line {
        //     display: flex;
        //     align-items: center;

        //     .cline {
        //         background-color: var(--themeColor);
        //         height: 3px;
        //         width: 100px;
        //     }

        //     .gline {
        //         background-color: rgba(128, 128, 128, 0.5);
        //         height: 1px;
        //         width: 100%;

        //     }
        // }

        .categories {
            display: flex;
            flex-direction: column;
            gap: .5rem;
            color: #505050;

            .category {
                display: flex;
                justify-content: space-between;
                padding: .2rem;
                font-size: 1.01rem;
                font-weight: 500;
                align-items: center;
                color: #505050;
                font-weight: 400;
                transition: all .2s;

                &:hover {
                    color: var(--themeColor);
                    font-weight: 600;
                }

                .titleBox {
                    display: flex;
                    gap: .5rem;
                    align-items: center;

                    .icon {
                        font-size: 1.4rem;
                    }

                    label {
                        cursor: pointer;
                    }

                    input {
                        cursor: pointer;

                    }
                }

                .counts {
                    // color: var(--themeColor);
                    color: #505050;
                    font-size: 15px;
                    font-weight: 400;
                }
            }
        }
    }

    .recentPostBox {
        display: flex;
        flex-direction: column;
        gap: .8rem;

        @media screen and (max-width:768px) {
            display: none;
        }

        .title {
            font-size: 1.2rem;
            font-weight: 700;
            color: #505050;


        }

        .singleBlogPosts {
            // margin-top: 10px;
            font-size: 15px;
            font-weight: 400;
            line-height: 18.9px;
            min-height: 50px;
            max-width: 100%;
            background: #FCD117;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        // .line {
        //     display: flex;
        //     align-items: center;

        //     .cline {
        //         background-color: var(--themeColor);
        //         height: 3px;
        //         width: 100px;
        //     }

        //     .gline {
        //         background-color: rgba(128, 128, 128, 0.5);
        //         height: 1px;
        //         width: 100%;

        //     }
        // }

        .posts {
            display: flex;
            flex-direction: column;

            .post {
                padding: .5rem;
                display: flex;
                gap: 1rem;

                .imgBox {
                    width: 90px;
                    min-width: 90px;
                    height: 90px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        border-radius: 5px;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .details {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    align-items: start;
                    color: #505050;
                    min-height: 75px;
                    max-width: 218px;

                    .heading {
                        font-size: 15px;
                        font-weight: 600;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #505050;

                        @media only screen and (max-width:920px) {
                            font-size: 15px;
                            font-weight: 500;

                        }

                    }

                    .shortDetail {
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 15px;
                        letter-spacing: 0em;
                        text-align: left;

                    }

                    .time {
                        color: #505050;
                        font-size: 8px;
                        font-weight: 400;
                        line-height: 12px;
                        letter-spacing: 0em;
                        text-align: left;

                    }
                }
            }
        }
    }

    .tagsBox {
        display: flex;
        flex-direction: column;
        gap: .8rem;

        @media screen and (max-width:768px) {
            display: none;
        }

        .title {
            font-size: 1.2rem;
            font-weight: 700;
        }

        .singleBlogTag {
            font-size: 15px;
            font-weight: 400;
            line-height: 18.9px;
            min-height: 50px;
            max-width: 100%;
            background: #FCD117;
            display: flex;
            align-items: center;
            justify-content: center;

        }

        // .line {
        //     display: flex;
        //     align-items: center;

        //     .cline {
        //         background-color: var(--themeColor);
        //         height: 3px;
        //         width: 100px;
        //     }

        //     // .gline {
        //     //     background-color: rgba(128, 128, 128, 0.5);
        //     //     height: 1px;
        //     //     width: 100%;

        //     // }
        // }

        .tags {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            color: #505050;

            .tag {
                padding: .8rem 2.5rem;
                // border: 1px solid rgba(128, 128, 128, 0.5);
                border-radius: 5px;
                overflow: hidden;
                color: #505050;
                text-align: center;
                font-size: 14px;
                font-weight: 400;
                background-color: #DFDFDF;
                cursor: pointer;
                transition: all .2s;

                &:hover {
                    font-weight: 600;
                    background-color: var(--themeColor);
                }
            }
        }
    }
}