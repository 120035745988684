.mainContainer {
    width: 100%;
    min-height: 900px;

    .topImg {

        display: flex;
        align-items: center;
        justify-content: center;

        .img {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 500px;
            background-image: url("../../Assets/Images/donation.png");
            filter: brightness(90%);


            color: #fcd117;
            width: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            object-fit: contain;

            @media screen and (max-width:590px) {
                height: 250px !important;
            }

        }



        .imgData {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            width: 60%;

            h2 {
                font-size: 30px;
                font-weight: 600;
                color: #FFF;
            }

            p {
                margin-top: 1rem;
                text-align: center;
                color: #e9e1e1;
                font-size: large;
            }

            @media screen and (max-width:590px) {
                margin-top: 2.1rem;
                width: 100%;
                padding: 1rem;
                text-align: center;

                h2 {
                    margin-top: 0 !important;
                    font-size: 17px !important;
                    font-weight: 600 !important;
                }

                p {
                    margin-top: 0 !important;
                    font-size: 10px !important;
                }

            }


        }
    }



    .paymentBtn {
        .shouldYellow {
            background: #fcd117 !important;
        }

        button {
            background: #dddddd;
            width: 127px;
            padding: .75rem 0;
            border-radius: 0.375rem
        }

    }

    .active {
        background: #fcd117 !important;
    }
}

.react-tel-input .form-control {
    width: 100%;
    height: 76px;
    border: none;
    padding-left: 88px;
    color: rgb(148 163 184);
}

.react-tel-input .flag-dropdown {
    position: absolute;
    padding: 34px;
    background-color: transparent;
    border: none;
}

.shadow {
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1) !important;
}

.react-tel-input .selected-flag .arrow {
    display: none;
}