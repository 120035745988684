.CalendarStudentMain {
    margin: 2rem;

    .rbc-day-slot .rbc-events-container {
        margin-right: 0;
    }

    .rbc-timeslot-group {
        border-bottom: 1px solid #fafafa;
    }

    .rbc-time-content>*+*>* {
        border-left: 1px solid #fafafa;
    }

    .rbc-time-view {
        border: 1px solid #f1f1f1;
    }

    .rbc-day-slot .rbc-event {
        background: linear-gradient(94.04deg, #FFC01E 22.18%, #FFDB7E 100%);
        box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        border: transparent;
    }

    .rbc-ellipsis,
    .rbc-show-more,
    .rbc-row-segment .rbc-event-content,
    .rbc-event-label {
        border: transparent;
        width: 9rem;
    }

    .rbc-event,
    .rbc-day-slot .rbc-background-event {
        background: transparent;
        padding: 0;
    }

    .rbc-event:focus,
    .rbc-day-slot .rbc-background-event:focus {
        outline: 0px;
    }
    .calendarAll{
        @media screen and (max-width:800px) {
            display: none;            
        }
    }
    .calendarDay{
        display: none;
        @media screen and (max-width:800px) {
            display: block !important;            
        }
    }

}