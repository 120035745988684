.bagMainContainer {
    max-width: 1400px;
    margin: 0 auto;
    padding: 150px 0rem 0 0rem;
    font-family: 'Poppins', sans-serif !important;

    .bagHeader {
        padding: 7rem 5rem;
        font-weight: 700;
        font-size: 30px;
        color: #17212B;
        background: #FFFDF2;
    }

    .tablemain {
        background-color: transparent;
        box-shadow: none;
        font-family: 'Poppins';
    }



    .tablehead {
        color: #17212B;
        font-weight: 700;
        font-size: 17px;

    }

    .tablecell {
        border: none;
    }

    .bagBody {
        display: flex;
        justify-content: space-between;
        padding: 2rem 0rem;

        @media only screen and (max-width:1024px) {
            flex-direction: column;
            gap:2rem
        }

        .bagProductsMainContainer {
            display: flex;
            flex-direction: column;
            width: 70vw;

            @media only screen and (max-width:1024px) {
                width: 100%;
                padding: 0rem 2rem 0rem 3rem;
            }
        }

        .tablebody {
            padding-top: 1rem;
            .icon:hover{
                transform: scale(1.1);
                cursor: pointer;
            }
        }

        .bookDetail {
            display: flex;
            gap: 10px;
            align-items: center;

            img {
                height: 7rem;
                width: 7rem;
                border-radius: 10px;
                object-fit: cover;

            }

            .para {
                color: #A1A8C1;
                font-size: 10px;
                padding-top: 5px;
            }
        }

        .quantity {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            background: #FFF4C7;
            width: 4rem;

            .bagBtn {
                background: #FCD117;
                display: flex;
                border-color: transparent;
                padding: 5px;
                height: 1.6rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: black;

            }
        }

        .price {
            font-weight: 700;
        }

        .pricetotal {
            font-weight: 700;
        }

    }
    
    .clearOut{
        margin-top: 2rem;
        color: white;
        display: flex;
        align-items: center;
        align-self: center;

    }

    .boldhead{
        font-weight: 600;
    }
    .clearOutBtn {
        margin-top: 2rem;
        color: white;
        background: #FCD117;
        border-radius: 3px;
        font-weight: 700;
        font-size: 18px;
        padding: 1.5rem 0rem;
        width: 10rem;
        display: flex;
        align-items: center;
        justify-self: center;
        justify-content: center;
        align-items: center;
        border: transparent;
    }

    .clearOutBtn:hover {
        color: white;
    }

    .totalMainContainer {
        display: flex;
        align-items: center;
        padding: 1rem;
        flex-direction: column;
        gap: 2rem;

        .headTotal {
            font-weight: 700;
        }

        .totalContainer {
            background: #F4F4FC;
            border-radius: 3px;
            height: fit-content;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 25rem;
            min-width: 20rem;

            @media only screen and (max-width:425px) {
                width: 20rem;
            }

            .checkbox {
                font-size: 12px;
                color:#8A91AB;
            }

            .checkbtn {
                color: #FCD117;
                border: none;
                border-radius: 3px;
                box-shadow: none;
                background-color: transparent;
                height: 3px;
                width: 3px;
                text-align: center;
            }

            .flexLine {
                justify-content: space-between;
                border-bottom: 2px solid #E8E6F1;
                padding-top: 1rem;
            }

            .checkoutBtn {
                margin-top: 2rem;
                color: white;
                background: #FCD117;
                border-radius: 3px;
                font-weight: 700;
                font-size: 18px;
                padding: 1.5rem 0rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border: transparent;

            }
        }
    }
}