.singleblogContainer {
    width: -webkit-fill-available;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 .8rem;
    margin-top: 40px;
    gap: 90px;
    // border: 1px solid red;

    @media only screen and (max-width:820px) {
        gap: 2rem;
        margin-top: 2rem;
        justify-content: center;
    }

    @media only screen and (max-width:920px) {
        gap: 30px;
    }

    .sideIcons {
        // border: 1px solid red;
        min-width: 31px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 21px;

        p {
            font-size: 16px;
            font-weight: 300;
            line-height: 22px;
            letter-spacing: 0px;
            text-align: center;

        }

        .icon {
            cursor: pointer;
            background: #FCD117;
            height: 31px;
            width: 31px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @media only screen and (max-width:880px) {
            flex-direction: row;
            align-items: center;
            justify-content: start;
        }

    }

    .blogContainer {
        width: 100%;
        max-width: 750px;

        .aboveImgDetails {
            display: flex;
            justify-content: space-between;
            align-items: center;


            .profileData {
                display: flex;
                align-items: flex-start;
                justify-content: baseline;
                gap: .5rem;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }

                .details {
                    .name {
                        font-size: 16px;
                        font-weight: 300;
                        line-height: 22px;
                        color: #183B56;
                    }

                    .bio {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 5px;
                        font-size: 14px;
                        font-style: italic;
                        font-weight: 400;
                        line-height: 19px;
                        letter-spacing: 0px;
                        color: #5A7184;

                        .icon {
                            border-radius: 50%;
                            color:
                                #36B37E;
                            background: #e1f4ec;
                        }
                    }
                }

            }

            .publishedDate {
                font-size: 14px;
                font-weight: 300;
                line-height: 20px;
                letter-spacing: 0px;
                text-align: right;
                color: #5A7184;
            }
        }

        .blog {
            margin-top: 40px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 40px;
            flex-wrap: wrap;

            .imgBox {
                width: 100%;
                position: relative;
                height: 360px;
                margin-bottom: 0;
                border-radius: 10px;

                @media screen and (max-width:425px) {
                    height: 250px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: fill;
                    border-radius: 10px;

                }
            }


            .authorBox {
                display: flex;
                align-items: center;
                gap: 1rem;
                padding: .3rem;
                border-radius: 50%;

                @media screen and (max-width:425px) {
                    font-size: 11px;
                }

                @media screen and (max-width:320px) {
                    gap: 0.3rem;
                    padding: 0rem;
                }

                .avaterBox,
                .timeBox,
                .likeBox {
                    display: flex;
                    gap: .5rem;
                    align-items: center;

                    .autherImage {
                        height: 50px;
                        width: 30px;
                        object-fit: contain;
                        border-radius: 50%;

                    }
                }
            }

            .title {
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                letter-spacing: 0.20000000298023224px;
                text-align: left;
                cursor: pointer;

                &:hover {
                    color: var(--themeColor);
                }
            }

            .quotBox {
                display: flex;
                gap: 1rem;

                .upperComma {
                    width: 35px;
                    color: var(--themeColor);
                    font-size: 1rem;
                }

                .lowerComma {
                    align-self: self-end;
                    width: 35px;
                    color: var(--themeColor);
                    font-size: 1rem;
                }

            }

            .blogDetail {
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0px;
                text-align: left;
            }


            .tagBox {
                display: flex;
                justify-content: space-between;
                padding: 2rem;
                border-radius: .3rem;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

                .tags,
                .socialLinks {
                    display: flex;
                    gap: .5rem;
                    justify-content: center;
                    align-items: center;

                    .tag {
                        padding: .8rem 1.5rem;
                        border-radius: 2rem;
                        overflow: hidden;
                        font-size: small;
                        cursor: pointer;
                        background-color: var(--lightYellow);

                        &:hover {
                            background-color: var(--themeColor);
                        }
                    }

                    .icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 5rem;
                        border: 1px solid var(--lightYellow);
                        cursor: pointer;
                        padding: .7rem;
                        font-size: 1rem;

                        &:hover {
                            background-color: var(--themeColor);
                        }
                    }
                }
            }

            .profileBox {
                display: flex;
                gap: 20px;
                align-items: start;
                width: 100%;
                min-height: 114px;
                border-bottom: 1px solid rgba(128, 128, 128, 0.507);
                // margin-bottom: 30px;

                .avater {
                    width: 80px;
                    height: 80px;

                    .autherImage {
                        width: 100%;
                        height: 100%;
                        object-fit: fill;
                        border-radius: 50%;
                    }
                }

                .details {
                    max-width: 612px;
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    gap: 10px;

                    .name {
                        font-size: 32px;
                        font-weight: 700;
                    }

                    .bio {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 28px;
                        letter-spacing: 0px;
                        text-align: left;

                    }

                }
            }

        }

        .commentsBox {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 3.5rem;
            // padding: 1.5rem;

            .title {
                font-size: 2rem;
                font-weight: bold;
            }

            .comments {
                display: flex;
                align-items: start;
                min-height: 124px;
                width: 100%;
                gap: 41px;

                @media only screen and (max-width:600px) {
                    flex-direction: column;
                    gap: 1rem;
                }


                .leftDetails {
                    display: flex;
                    gap: 12px;

                    .avater {
                        width: 40px;
                        height: 40px;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            object-fit: fill;
                        }
                    }

                    .commentGiverprofile {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        min-width: 110px;

                        .name {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 19px;
                            letter-spacing: 0px;
                            text-align: left;
                            color: #A9A9A9;
                        }

                        .time {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 20px;
                            letter-spacing: 0px;
                            text-align: left;

                        }
                    }

                }

                .details {
                    background: #f4f5f6;
                    height: 100%;
                    width: 100%;
                    max-width: 555px;
                    min-height: 124px;
                    padding: 20px 20px 20px 30px;
                    border-radius: 0 15px 15px 15px;

                    .bio {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 28px;
                        letter-spacing: 0px;
                        text-align: left;

                    }

                }
            }


            .writeComment {

                border: 1px solid #E5EAF4;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                background: inherit;
                height: 64px;
                padding: 12px;

                // @media only screen and (max-width:820px) {
                //     margin-top: 2rem;
                // }

                .img {
                    width: 100%;
                    max-width: 40px;
                    height: 40px;


                    .userPic {
                        border-radius: 50%;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;

                    }
                }

                .ant-input {
                    height: 40px;
                    font-size: 15px;
                    padding: 0 1rem;
                    background: inherit;
                    border: none;
                    outline: none;
                    box-shadow: none;
                }

                .ant-btn {
                    height: 40px;
                    width: 156px;
                    border: none;
                    outline: none;
                    box-shadow: none;
                    color: black;
                    border-radius: .2rem;
                    transition: all .2s;

                    &:hover {
                        transform: scale(1.02);
                        color: black;
                        background: #fcd117;
                    }
                }
            }

        }

        .loginPrompt {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .commentSection {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1.5rem;
            padding: 1.5rem 0;
            width: 100%;
            height: 330px;

            .reviewComment {

                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.22);
                border-radius: .4rem;

                @media only screen and (max-width:800px) {
                    width: 100%;
                }

                .rComment {
                    padding: .5rem 2.5rem;
                    display: flex;
                    justify-content: space-evenly;
                    flex-direction: column;
                    background: #fcd117;
                    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.22);

                    .title {
                        font-size: 1.5rem;
                        font-weight: bold;
                    }

                    .hr {
                        width: 100;
                        margin: .5rem -2.5rem;
                        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.22);
                    }
                }

                .profileData {
                    display: flex;
                    padding: 0 0 0 1rem;

                    .avater {
                        width: 100%;
                        display: flex;
                        height: 70px;
                        gap: 1rem;

                        .img {
                            width: 50px;
                            height: 50px;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: fit;
                                border-radius: 50%;
                            }
                        }

                        .imgDetails {
                            height: 100%;

                            .mail {
                                font-size: 13px;
                                color: gray;
                            }

                            .mailDetail {
                                font-size: 17px;
                                font-weight: 600;
                            }

                            .stars {
                                font-size: 12px;

                                .rate {
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                }

                .writePara {
                    overflow-y: hidden;
                    height: 50px;
                    padding: 0 1rem;

                    textarea {
                        width: 100%;
                        border: 1px solid rgba(0, 0, 0, 0.3);
                        padding: 12px 20px;
                        border-radius: 5px;
                        height: 100%;
                    }
                }

                .form {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 1rem;
                    padding: 0 1rem 1rem 0;

                    .ant-btn {
                        height: 40px;
                    }

                    .btnComment {
                        padding: .1rem 2rem;
                        color: black;
                        border-radius: .2rem;
                        transition: all .2s;
                        border: none;
                        outline: none;

                        &:hover {
                            transform: scale(1.02);
                            color: black;
                            background: #fcd117;
                            border: none;
                            outline: none;
                        }
                    }
                }

            }
        }
    }


    .sidebar {
        min-width: 318px;

        @media only screen and (max-width:1100px) {
            display: none;
        }
    }
}