.containerAllBlogs {

  .AllBlogs {
    display: grid;
    grid-template-columns: 360px auto;
    gap: 2rem;
    padding: 0 .5rem;

    .sidebar {
      padding-top: 6.7rem;
      height: 100%;
    }

    @media only screen and (max-width:1000px) {
      grid-template-columns: 250px auto;
    }


    @media only screen and (max-width:800px) {
      display: flex;
      flex-direction: column;

      .sidebar {
        padding-top: 1rem;
      }
    }

    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
      border-color: #FCD117 !important;
    }

    .ant-select-focused:where(.css-dev-only-do-not-override-htwhyh).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
      border-color: #FCD117 !important;
    }

    .skeleton {
      display: flex;
      flex-direction: column;
      height: 100vh;
      justify-content: center;
      margin-top: 2.4rem;

      .skel {
        width: 100%;
        height: 15rem;
        margin-bottom: 1rem;

      }
    }


    .blog-page {

      .singleBlogPageLayout {
        min-height: 252px;
        border-radius: 5px;
        box-shadow: 3px 5px 15px 4px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        padding: 30px 26px;

        @media only screen and (max-width:920px) {
          flex-direction: column !important;
        }

        .img {
          min-height: 240px;
          overflow: hidden;
          cursor: pointer;
          transition: ease-in-out .5s;

          @media only screen and (max-width:920px) {
            min-height: 300px;
          }

          &:hover {
            transform: scale(1.1);
          }
        }

        .blogDetailContainer {
          margin-left: 30px;
          max-width: 605px;

          .blogTitle {
            margin-top: 12px;
            font-size: 24px;
            font-weight: 700;
            letter-spacing: 0em;
            text-align: left;
            color: #323232;

          }

          @media only screen and (max-width:768px) {
            margin-left: 10px;

            .blogTitle {
              margin-top: 5px;
              font-size: 18px;
              font-weight: 600;
            }
          }


          .blogStarts {
            margin-top: 10px;
            min-height: 11px;
            display: flex;
            align-items: flex-end;
            gap: 5px;

            .ratingValue {
              font-size: 12px;
              font-weight: 400;
              line-height: 14px;
              letter-spacing: 0em;
              text-align: left;
            }

            .starts {
              font-size: 12px;
              height: 100%;
              width: 100%;
            }
          }


          .profileData {
            color: #323232;
            font-size: 11px;
            font-weight: 400;
            letter-spacing: 0em;
            text-align: left;
          }
        }
      }

      .blog-content {
        margin-top: 12px;
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        overflow: hidden;
        padding: 0;
      }
    }

    .my-card {
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      box-shadow: 0px 6px 20px 3px #00000026;
      border-radius: 3px;
      overflow: hidden;
      margin-bottom: 45px;
      height: 550px;
      width: 100%;
      padding: 44px 23px;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 9px rgba(0, 0, 0, 0.2);
      }

      .image {
        height: 800px;
        width: 100%;
        overflow: hidden;
        transition: all .5s;

        &:hover {
          border-radius: 8px;
          transform: scale(1.05);
        }

        img {
          transition: all .5s;
          width: 100%;
          height: 100%;
          object-fit: fill;
          border-radius: 8px;

        }

        .date-card {
          position: absolute;
          top: 15px;
          left: 15px;
          z-index: 2;
          display: grid;
          width: 100%;
          padding: auto;
          background-color: var(--themeColor);
          box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
          color: var(--color-gray-800);
          text-align: center;
          line-height: 1;

          .date-day {
            padding-top: 6px;
            padding-bottom: 4px;
            font-size: 24px;
          }

          .date-month {
            padding-top: 2px;
            padding-bottom: 8px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 12px;
          }
        }
      }

      .content {
        margin-top: 20px;
        text-align: center;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 20px;

        .tag {
          position: relative;
          bottom: 27px;
          left: 2px;
          overflow: hidden;
          height: 25px;
          line-height: 25px;

          .tag-body {
            display: inline-block;
            overflow: hidden;
            padding-right: 30px;
            padding-left: 30px;
            height: 25px;
            border-radius: calc(var(--wd-brd-radius) / 1.5);
            background-color: var(--themeColor);
            color: #fff;
            line-height: 25px;
          }
        }

        .title {
          font-size: 16px;
          font-weight: 600;
          line-height: 23px;
          letter-spacing: 0em;
          text-align: center;

        }

        .details {
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: justified;
          color: #42464E;
        }

        .author-data {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          width: 100%;

          .author-list {
            width: 100%;
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            margin-right: 10px;
            vertical-align: middle;
            font-weight: 400;

            .list {
              display: flex;
              margin-right: 10px;
              color: gray;
              vertical-align: middle;
              line-height: 1.4;
            }

            .avatar {
              margin: 0 5px;
              width: 18px;
              height: 18px;
              border-radius: 50%;
              vertical-align: top;
            }

            .like {
              padding-left: 5px;
              display: flex;
              gap: 0.2rem;
              align-items: center;
            }

            .icon {
              cursor: pointer;
            }

            .share-icon {
              padding-left: 5px;
              margin-top: 3px;
            }

            .comment-icon {
              padding-left: 5px;
              margin-top: 3px;
            }
          }
        }

        .details {
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: justify;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        .button {
          cursor: pointer;
          color: var(--themeColor);
          width: 110px;
          padding: 0.4rem 0.3rem;
          font-weight: bold;

          &:hover {
            background-color: var(--themeColor);
            color: white;
          }
        }
      }
    }
  }
}

.ant-select-selector {
  box-shadow: none !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid black !important;
  border-radius: 0% !important;
  transition: ease-in-out .2s;

  &:hover {
    border-bottom: 1px solid #FCD117 !important;
  }
}

.customSelector {
  box-shadow: 0px 2px 4px 0px #0000001A;
  padding: 10px 4px;
}

.ant-select-item-option {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 2px 4px 0px #0000001A;
}

.ant-select-item-option-selected {
  background: #FCD117 !important;
}

.ant-select-item-option-active {
  background: #FCD117 !important;
}

.rc-virtual-list-holder-inner {
  gap: 10px;
}

.ant-select-dropdown .ant-select-item-option-content {
  color: #42464E;
}