#styledBrowser {
  * {
    transition: 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: 0.4s ease-in-out;
  }
}

// .flex-price {
//   padding-top: 10px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;

//   .addToCartBtn {
//       background: #FBD434;
//       border-radius: 2px;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       padding: 1rem 1rem;
//       border-radius: 10px;
//       font-weight: bold;
//       gap: 10px;
//       color: black;
//       border: transparent;
//   }

//   .pricepara {
//       font-size: 1.7rem;
//       font-weight: bold;
//       padding-bottom: 18px;
//   }

//   .Categories {
//       font-size: 20px;
//       font-weight: 100;
//       font-family: "lightFont";
//   }

//   .primary-button2 {

//       button {
//           width: 150px;
//           height: 50px;
//           font-size: 15px;
//           cursor: pointer;
//           background: #FBD434;
//           font-family: 'Poppins';
//           font-weight: 400;
//           border-radius: 5px;
//           transition: all 0.3s ease;
//           box-shadow: inset 2px 2px 2px 0px transparent,
//               7px 7px 20px 0px rgba(0, 0, 0, .1),
//               4px 4px 5px 0px rgba(0, 0, 0, .1);

//       }

//       .pri2-btn {
//           box-shadow: none;
//           line-height: 52px;
//           display: flex;
//           align-items: center;
//           justify-content: center;

//           @media screen and (max-width:"1450px") {
//               line-height: 45px;
//               width: 150px;
//               height: 50px;
//               padding-left: 20px;

//           }

//           @media screen and (max-width:"768px") {
//               line-height: 45px;
//               width: 137px;
//               height: 42px;
//           }

//       }

//       .pri2-btn span {
//           background: linear-gradient(0deg, var(--themeColor) 0%, var(--themeColor) 100%);
//           display: block;
//           position: absolute;
//           width: 150px;
//           height: 50px;
//           border-radius: 5px;
//           margin: 0;
//           text-align: center;
//           -webkit-box-sizing: border-box;
//           -moz-box-sizing: border-box;
//           box-sizing: border-box;
//           -webkit-transition: all .3s;
//           transition: all .3s;

//           @media screen and (max-width:"1450px") {
//               font-size: 15px;
//               font-family: 'Poppins';
//               width: 130px;
//               height: 45px;

//           }

//           @media screen and (max-width:"768px") {
//               font-size: 13px;
//               font-family: 'Poppins';
//               width: 100px;
//               height: 40px;

//           }

//       }



//   }

// }

.cards {

  .slick-slide img {
    @media screen and (max-width: 1023px) {
      height: 15rem;
    }

    @media screen and (max-width: 767px) {
      border-radius: 15px;
      padding: 0 10px;
      height: 15rem;
    }
  }

  span {

    @media screen and (max-width: 767px) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
    }

    &.prevIcon {
      left: -12%;
    }

    &.nextIcon {
      right: -12%;
    }
  }

  .css-doq0dk .css-1fzpoyk img {
    height: 410px;
  }
}

@keyframes spinRound {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}