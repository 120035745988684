.scroll-btn {
    color: #fff;
    width: 60px;
    height: 60px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 10px;
    background-color: var(--themeColor);
    position: fixed;
    bottom: 90px;
    right: 60px;
    z-index: 111;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width:"1200px") {
        right: 50px;
    }

    @media screen and (max-width:"1024px") {
        right: 40px;
    }

    @media screen and (max-width:"768px") {
        right: 20px;
        width: 50px;
        height: 50px;
    }

    .icon {
        width: 25px !important;
        height: 15px !important;

        @media screen and (max-width:"768px") {
            width: 20px !important;
            height: 15px !important;
        }
    }

}