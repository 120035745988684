.category-container {
    .category-header {
        width: 100%;
        height: 55vh;
        background: url('../../Assets/Images/categorybg.png');
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8rem;
        background-size: 100% 100%;
        font-family: 'Poppins', sans-serif !important;

        @media screen and (max-width:"1450px") {
            height: 50vh;
            gap: 5rem;
        }

        @media screen and (max-width:"1024px") {
            background-size: 100% 85%;

        }

        @media screen and (max-width:"768px") {
            background-size: 100% 65%;
        }

        .heading {
            padding-top: 7rem;
            font-size: 120px;
            color: #fff;
            font-weight: 700;

            @media screen and (max-width:"1450px") {
                font-size: 70px;
            }

            @media screen and (max-width:"1024px") {
                font-size: 50px;
                padding-top: 4rem;
            }

            @media screen and (max-width:"768px") {
                font-size: 50px;
                padding-top: 0rem;
            }
        }

    }

    .category-cards {
        padding-top: 5rem;

        @media screen and (max-width:"425px") {
            padding: 1rem;
        }

        .skeleton {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 1rem;
            height: 100vh;
            margin-top: 4rem;

            .skel {
                height: 20rem;
                width: 20rem;
                margin: 2rem;
            }
        }

        .flex-heading {
            padding: 1rem 0rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 2px solid #4242425b;

            @media screen and (max-width:"1024px") {
                // padding: 1rem;
            }


            .heading {
                font-size: 42px;

            }
        }

        .cards {
            padding: 4rem 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            gap: 3rem;


            .card {
                border-radius: 3px;
                padding: .5rem;
                width: 280px;
                height: 440px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                cursor: pointer;
                overflow: hidden;

                img {
                    transition: all .5s;
                    border-radius: 3px;
                    object-fit: fit;
                    display: flex;
                    justify-content: center;
                }

                .cardImage {
                    width: 100%;
                    height: 290px;
                }

                .bio {
                    padding: 2rem 1rem;

                    .stars {
                        display: flex;
                        align-items: center;

                        .rate {
                            padding: 0;
                            font-size: 1rem;

                        }

                        .span {
                            margin-left: 1rem;
                        }
                    }

                    .flex-bio {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .heading {

                            cursor: pointer;
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 800;
                            line-height: normal;
                            text-transform: capitalize;
                            transition: ease-in-out .1s;
                        }

                        .heading:hover {
                            color: var(--themeColor);
                        }

                    }

                    .flex-price {
                        padding-top: 10px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .pricehead {
                            font-size: 10px;
                            font-style: normal;
                            font-weight: 450;
                            line-height: normal;
                            text-transform: capitalize;
                        }

                        .pricepara {
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-transform: capitalize;
                        }

                        .Categories {
                            font-size: 30px;
                            font-weight: 100;
                            font-family: "lightFont";
                        }

                        .primary-button2 {

                            display: flex;
                            align-items: center;
                            justify-content: space-between;


                            button {

                                width: 100px;
                                height: 35px;
                                font-size: 18px;
                                cursor: pointer;
                                background: #FBD434;
                                border-radius: 3px;
                            }

                            .pri2-btn {
                                display: flex;
                                align-items: center;
                                justify-content: space-evenly;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                text-transform: capitalize;




                            }




                        }

                    }

                }

                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                }

            }
        }

    }
}