.TeachersContainer {
  // padding: 12rem 0;
  display: grid;
  grid-template-columns: 340px auto;
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;

  @media only screen and (max-width: 1880px) {
    grid-template-columns: 280px auto;
  }
  @media screen and (max-width:1400px) {
    grid-template-columns: 250px auto;
}

  @media only screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
  .TeacherSideBarContainer {
    display: flex;
    flex-direction: column;
    // background-color: #fff5c9;
    padding: 0rem 2rem 2.5rem;
    margin-bottom: 30px;
    gap: 1rem;
  @media only screen and (max-width: 1880px) {
    padding: 0rem 0rem 0rem;
  }
  @media only screen and (max-width: 800px) {
    margin-bottom: 0px;
  }
  .Teacher, .Study, .gender{
    @media only screen and (max-width: 800px) {
      display: none;
    }
  }
    .barHeading {
      display: flex;
      padding: 10px;
      border-radius: 7px;
      background-color: var(--themeColor) !important;
      color: rgba(0, 0, 0, 0.88);
      font-weight: 600;
      font-size: 16px;
      justify-content: center;
    }
    .ant-card-body{
      margin-top: 1rem;
      background-color: white;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
    }
    .ant-card:not(.ant-card-bordered){
      box-shadow: none;
    }

    .searchBox {
      width: 100%;
      border: 1px solid var(--themeColor);
      display: flex;
      border-radius: 7px;
      overflow: hidden;
      // margin-left: 16px;

      input {
        width: -webkit-fill-available;
        padding-left : 0.7rem;
        margin-left: 0px;
        font-size: 13px;
      }

      .imgBox {
        padding: 0.5rem 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--themeColor);
        cursor: pointer;
        font-size: 1.5rem;
      }
    }
    // ***********GenderCard***************//
    .genderCard {
      width: 280px;
      .levelCourse{
        @media screen and (max-width:1400px) {
          font-size: 12px;
        }
        @media screen and (max-width:800px) {
          font-size: 10px;
        }
        
    }
      .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector{
        border-color: #d9d9d9;
        box-shadow: none;
      }
      .ant-select-selector {
        border-color: #d9d9d9;
        box-shadow: none;
    }
      .ant-select-selector:hover {
        border-color: #d9d9d9;
        box-shadow: none;
    }
      @media screen and (max-width:1400px) {
        width: 250px;
    }
      @media screen and (max-width:800px) {
        width: 200px;
    }

      .ant-card-head {
        color: rgba(0, 0, 0, 0.88);
        font-weight: 600;
        font-size: 16px;
        background-color: var(--themeColor) !important;
      }
    }
    // ***********Progress bar***************//

    .ant-progress-line {
      width: 111%;
    }
    .ant-progress-text {
      display: none;
    }
    .ant-radio-wrapper{
      font-size: 13px;
    }

    .ant-progress-bg {
      background-color: var(--themeColor) !important;
    }
    .ant-progress-status-success .ant-progress-bg {
      background-color: var(--themeColor) !important;
    }
    .ant-progress-steps-item-active {
      background-color: var(--themeColor);
    }
    .ant-slider .ant-slider-track {
      background-color: var(--themeColor);
      border-radius: 7px;
    }
    .ant-select-selector {
      border: 1px solid #d9d9d9;
  }
    .ant-select-selector:hover {
      border: 1px solid #d9d9d9;
  }
    .ant-slider .ant-slider-handle::after {
      content: "";
      box-shadow: 0 0 0 2px var(--themeColor);
    }

    .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
      border-color: var(--themeColor);
      background-color: var(--themeColor);
  }
  .ant-radio-wrapper:hover .ant-radio-inner {
    border-color: var(--themeColor);
}
    // .anticon svg {
    //   display: none;
    // }

 
  }
}
