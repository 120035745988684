.freeBookSec {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  .popUpBook {
    // background: rgba(0, 0, 0, 0.5) !important;
    // top: 0;
    // height: 100vh;
    // width: 100%;
    // position: absolute;
  }

}
.ant-modal-content{
  padding: 50px 25px !important;
}
.ant-modal-mask{
  background-color: rgba(0, 0, 0, 0.6) !important;
}

// .shadow {
//   box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1) !important;
// }