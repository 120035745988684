.secondary-button {

    button {
        width: 150px;
        height: 50px;
        font-size: 20px;
        cursor: pointer;
        color: var(--dark);
        font-family: 'lightFont';
        font-weight: 500;
        border-radius: 5px;
        background: transparent;
        transition: all 0.3s ease;
        position: relative;
        box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
            7px 7px 20px 0px rgba(0, 0, 0, .1),
            4px 4px 5px 0px rgba(0, 0, 0, .1);

        @media screen and (max-width:"1450px") {
            width: 130px;
            height: 44px;

        }

        @media screen and (max-width:"768px") {
            width: 100px;
            height: 44px;
            font-size: 13px;


        }
    }

    .sec-btn {
        line-height: 42px;
        padding: 0;
        border: none;

        &:hover {
            box-shadow: none;
        }
    }

    .sec-btn:before,
    .sec-btn:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 2px;
        width: 0;
        background: var(--themeColor);
        box-shadow:
            -1px -1px 5px 0px #fff,
            7px 7px 20px 0px #0003,
            4px 4px 5px 0px #0002;
        transition: 400ms ease all;
    }

    .sec-btn:after {
        right: inherit;
        top: inherit;
        left: 0;
        bottom: 0;
    }

    .sec-btn:hover:before,
    .sec-btn:hover:after {
        width: 100%;
        transition: 800ms ease all;
    }
}