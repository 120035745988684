.events-container {
    padding: 7rem 0;

    @media screen and (max-width:"1450px") {
        padding: 5rem 0;
    }

    @media screen and (max-width:"1024px") {
        padding: 3rem 0;
    }


    .events {
        display: flex;
        flex-direction: column;
        gap: 5rem;

        @media screen and (max-width:"1450px") {
            gap: 3rem;

        }


        .header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;

            .event {
                color: var(--themeColor);
                font-size: 20px;
                font-family: "darkFont";
            }

        }

        .cards {
            display: flex;
            justify-content: space-between;
            gap: 2rem;
            flex-wrap: wrap;
            padding: 1rem;

            @media screen and (max-width:"1100px") {
                flex-direction: column;
            }

            .card {
                width: 45%;
                display: flex;
                background: #fff;
                box-shadow: 0 5px 9.8px 0.2px rgb(85 85 85 / 7%);

                @media screen and (max-width:"1100px") {
                    width: 100%;

                }

                @media screen and (max-width:"768px") {
                    flex-direction: column;

                }

                .img {
                    position: relative;
                    width: 244px;
                    height: 244px;

                    @media screen and (max-width:"1100px") {
                        width: 181px;
                        height: 181px;
                    }

                    @media screen and (max-width:"768px") {
                        width: -webkit-fill-available;
                        height: -webkit-fill-available;
                    }

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }

                    .badge {
                        position: absolute;
                        top: -8px;
                        left: 0;
                        background: url('../../../Assets/Images/badge.png');
                        width: 56px;
                        height: 82px;
                        padding-top: 4px;
                        padding-right: 8px;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        flex-wrap: wrap;
                        color: #fff;
                        font-family: 'darkFont';
                        font-size: 22px;

                        @media screen and (max-width:"425px") {
                            font-size: 15px;
                        }
                    }

                }

                .content {
                    display: flex;
                    flex-direction: column;
                    gap: .5rem;
                    padding: 34px 20px;

                    .heading {
                        font-size: 30px;
                        font-family: 'darkFont';

                        @media screen and (max-width:"1450px") {
                            font-size: 22px;
                        }
                    }

                    .bio {
                        display: flex;
                        gap: 1rem;

                        .time {
                            display: flex;
                            gap: .5rem;

                            @media screen and (max-width:"1450px") {
                                font-size: 11px;
                            }
                        }

                    }

                    .para {
                        font-size: 18px;
                        line-height: 1.8em;
                        color: rgba(14, 13, 13, 0.5);


                        @media screen and (max-width:"1450px") {
                            font-size: 16px;
                        }
                    }

                    .link {
                        font-family: 'darkFont';
                        color: var(--themeColor);
                    }
                }
            }
        }
    }
}