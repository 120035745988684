.teacherMainContainer {
    padding-bottom: 3rem;
    font-family: 'Poppins', sans-serif !important;
    background-color: white;

    .FindTeacherHearder {
        color: white;

        .imageFindTeacher {
            position: relative;
            width: 100%;
            z-index: 1;
            height: 24rem;
        }

        .imageFindTeacher2 {
            position: absolute;
            top: 0.3rem;
            width: 100%;
            height: 26rem;
        }

        .FindTeachHead {
            position: absolute;
            top: 10rem;
            right: 0;
            left: 0;
            z-index: 2;
            align-self: center;
            text-align: center;

            @media screen and (max-width:600px) {
                top: 8rem;
            }

            .teachHead {
                font-weight: 600;
                font-size: 31px;
                line-height: 47px;
                text-transform: uppercase;

                @media screen and (max-width:1200px) {
                    font-size: 20px;
                }
            }

            .teachdetail {
                font-style: normal;
                font-weight: 400;
                font-size: 13.39;
                line-height: 20px;

                @media screen and (max-width:1200px) {
                    font-size: 12px;
                }
            }
        }
    }

    .TeachContainer {
        margin-top: 4rem;
        display: flex;
        padding-top: 59px;
        width: 100%;
        margin: 0 auto;
        gap: 36px;
        justify-content: space-around;

        @media screen and (max-width:800px) {
            margin-top: 1rem;
        }

        @media only screen and (max-width: 1760px) {
            width: 100%;
            padding-left: 1rem;
        }

        @media only screen and (max-width: 800px) {
            flex-direction: column;
        }



        .filters {
            width: 15%;
            margin-top: 2.7rem;

            @media screen and (max-width:1500px) {
                width: 15%;
                padding-left: 0.5rem;
                margin-right: 0.5rem;
            }

            @media screen and (max-width:1200px) {
                margin-top: 0rem;
            }

            @media screen and (max-width:1080px) {
                width: 25%;
                padding-left: 0px;
            }

            @media screen and (max-width:800px) {
                width: 100%;
                padding: 0.5rem;
            }
        }

        .teacherDetailCart {
            width: 80%;
            display: flex;
            flex-direction: column;
            margin-right: 5px;
            padding-right: 2rem;
            gap: 17px;

            @media screen and (max-width:1580px) {
                width: 75%;
            }

            @media screen and (max-width:1400px) {
                padding-right: 0.5rem;
                width: 65%;
            }

            @media screen and (max-width:800px) {
                padding-right: 0.5rem;
                width: 100%;
            }

            .skeleton {
                display: flex;
                flex-direction: column;
                height: 120vh;
                justify-content: center;

                .skel {
                    width: 100%;
                    height: 20rem;
                    margin-bottom: 1rem;

                }
            }

            .sortBtn {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 10px;

                @media screen and (max-width:1200px) {
                    display: none;
                }
            }

            .result {
                text-transform: uppercase;
                align-items: center;
                display: flex;
                padding: 10px;
                border-radius: 7px;
                background-color: var(--themeColor) !important;
                color: rgba(0, 0, 0, 0.88);
                font-weight: 600;
                font-size: 16px;
            }

            .singleCart {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: 1rem;

                .carts {
                    border-radius: 4.535px;
                    background: var(--whitecolor, #FFF);
                    box-shadow: 0px 9px 13px 0px rgba(0, 0, 0, 0.25);
                    width: 100%;
                    height: 20rem;
                    padding: 0.5rem;
                    margin-bottom: 2rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    position: relative;

                    @media screen and (max-width:550px) {
                        flex-direction: column;
                        height: fit-content;
                    }

                    .courseView {
                        display: flex;
                        flex-direction: column;
                        width: 100%;

                        .teacherProfile {
                            width: 85%;
                            padding: 0.7rem;
                            display: flex;
                            align-items: center;

                            @media screen and (max-width:800px) {
                                width: 100%;
                            }

                            .teacherNameDesc {
                                padding-left: 1rem;

                                .TeacherName {
                                    font-size: 22px;
                                    font-weight: 600;
                                }

                                .detail {
                                    font-size: 13px;
                                    font-weight: 400;
                                }

                                .ratingPara {
                                    display: flex;
                                    gap: 3px;
                                }

                                .ant-rate-star {
                                    margin-inline-end: 2px;
                                }
                            }

                            .TeacherImg {
                                border: 0.7px solid #FFFAE3;
                                border-radius: 7px;
                                height: 10rem;
                                width: 8rem;
                                object-fit: cover;
                            }

                            .onlineMain {
                                position: absolute;
                                right: 1rem;
                                top: 1rem;

                                .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
                                    opacity: 0;
                                }

                                .ant-radio-inner {
                                    box-shadow: 0px 0px 10px rgba(0, 255, 0, 0.75);
                                    border-color: green;
                                    background-color: green;
                                }
                            }
                        }

                        .teacherOffers {
                            .tableMain {
                                font-size: 12px;
                                margin-top: 2rem;
                                width: 100%;
                                text-align: center;

                                @media screen and (max-width:550px) {
                                    font-size: 10px;
                                }

                                .primary-button2 {
                                    display: flex;
                                    justify-content: center;
                                }

                                .tableBody {
                                    padding-top: 1rem;
                                }

                                .tabled {
                                    padding-top: 0.7rem;
                                }

                                .tableh {
                                    font-weight: 500;
                                }

                                .lang,
                                .assign {
                                    @media screen and (max-width:930px) {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    .detailView {
                        width: 15%;
                        padding-top: 1rem;

                        @media screen and (max-width:550px) {
                            display: none;
                        }


                        .detail {
                            padding-top: 0.7rem;

                            .head {
                                font-size: 14px;
                                font-weight: 600;
                                padding-bottom: 0.2rem;
                            }

                            .para {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }

            .doubleCart {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 2rem;

                @media screen and (max-width:1200px) {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 2rem;

                }


                .doublecarts {
                    border-radius: 4.535px;
                    background: var(--whitecolor, #FFF);
                    box-shadow: 0px 9px 13px 0px rgba(0, 0, 0, 0.25);
                    width: 100%;
                    height: 20rem;
                    padding: 0.5rem;
                    margin-bottom: 2rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    position: relative;

                    @media screen and (max-width:1400px) {
                        flex-direction: column;
                        height: fit-content;
                    }


                    .doublecourseView {
                        display: flex;
                        flex-direction: column;
                        width: 100%;

                        .doubleteacherProfile {
                            width: 85%;
                            padding: 0.7rem;
                            display: flex;
                            align-items: center;
                            height: 13rem;

                            @media screen and (max-width:800px) {
                                width: 100%;
                            }

                            .doubleteacherNameDesc {
                                padding-left: 1rem;

                                .doubleTeacherName {
                                    font-size: 22px;
                                    font-weight: 600;
                                }

                                .doubledetail {
                                    font-size: 13px;
                                    font-weight: 400;
                                }

                                .ratingPara {
                                    display: flex;
                                    gap: 3px;
                                }

                                .ant-rate-star {
                                    margin-inline-end: 2px;
                                }
                            }

                            .doubleTeacherImg {
                                border: 0.7px solid #FFFAE3;
                                border-radius: 7px;
                                height: 10rem;
                                width: 8rem;
                                object-fit: cover;
                            }

                            .onlineMain {
                                position: absolute;
                                right: 1rem;
                                top: 1rem;

                                .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
                                    opacity: 0;
                                }

                                .ant-radio-inner {
                                    box-shadow: 0px 0px 10px rgba(0, 255, 0, 0.75);
                                    border-color: green;
                                    background-color: green;
                                }
                            }
                        }

                        .doubleteacherOffers {

                            .dtableMain {
                                font-size: 12px;
                                margin-top: 2rem;
                                width: 100%;
                                text-align: center;

                                @media screen and (max-width:800px) {
                                    font-size: 10px;
                                }

                                .primary-button2 {
                                    display: flex;
                                    justify-content: center;
                                }

                                .dtableBody {
                                    padding-top: 1rem;
                                }

                                .dtabled {
                                    padding-top: 0.7rem;
                                }

                                .dtableh {
                                    font-weight: 500;
                                }

                                .lang,
                                .assign {
                                    @media screen and (max-width:1600px) {
                                        display: none;
                                    }
                                }
                            }

                        }
                    }

                    .ddetailView {
                        width: 15%;
                        padding-top: 1rem;

                        @media screen and (max-width:1400px) {
                            display: none;
                        }

                        .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
                            opacity: 0;
                        }

                        .ant-radio-inner {
                            box-shadow: 0px 0px 10px rgba(0, 255, 0, 0.75);
                            border-color: green;
                            background-color: green;
                        }


                        .ddetail {
                            padding-top: 0.7rem;

                            .dhead {
                                font-size: 14px;
                                font-weight: 600;
                                padding-bottom: 0.2rem;
                            }

                            .dpara {
                                font-size: 13px;
                            }
                        }
                    }
                }

            }
        }
    }
}