.categories-container {
    padding: 4rem 0rem;

    .container {
        .heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
        padding-bottom: 2rem;

            @media screen and (max-width:1024px) {
                padding-left: 1rem;
                padding-right: 1rem;
    
            }
            @media screen and (max-width:768px) {
                flex-direction: column;
            
            }
        }

        .flex-categories {
            padding-top: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 1rem;

            .category-card {
                min-height: 440px;
                width: 337px;
                padding: 2.5rem;
                background-color: #CCFFCC;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                border-radius: 5px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

                @media screen and (max-width:"768px") {
                    padding: 1.5rem;
                    min-height: 400px;
                    width: 310px;

                }

                .logo {
                    height: 73px;
                    width: 73px;

                    .icon {
                        height: 100%;
                        width: 100%;
                    }
                }

                .name {
                    font-size: 20px;

                    @media screen and (max-width:"768px") {
                        font-size: 18px;

                    }
                }

                .bio {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 24px;
                    text-align: justify;
                    letter-spacing: 0.05em;

                    @media screen and (max-width:"768px") {
                        font-size: 16px;

                    }
                }
            }

            .light {
                background-color: #FFF5C9;
            }

            .dark {
                background-color: #FEE990;
            }
        }
    }
}